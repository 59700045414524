import { Project } from '@mayple/types';
import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { ProjectExistingMarketingEvaluationQuery } from 'growl-graphql/dist/queries/project/ProjectExistingMarketingEvaluationQuery';

export type ProjectExistingMarketingEvaluation =
  | Pick<Project, 'uuid' | 'id' | 'existingMarketingEvaluation' | 'specialType' | 'specialSubType'>
  | undefined;

export interface ProjectData {
  loading: boolean;
  error: ApolloError | undefined;
  project: ProjectExistingMarketingEvaluation;
  refetch: (variables?: { projectId: number }) => Promise<ApolloQueryResult<Project>>;
}

const useProjectExistingMarketingEvaluation = (
  projectId: number | null | undefined,
  options: QueryHookOptions = { notifyOnNetworkStatusChange: true },
): ProjectData => {
  const mergedOptions: QueryHookOptions = {
    variables: { projectId },
    skip: !projectId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    ...options,
  };

  const { loading, error, data, refetch } = useQuery(ProjectExistingMarketingEvaluationQuery.query, mergedOptions);

  return {
    loading,
    error,
    project: data?.project,
    refetch,
  };
};

export default useProjectExistingMarketingEvaluation;
