import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors, getGradientColor } from '../../../../app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    error: {
      color: colors.error_text,
    },
    rootViewMode: {
      display: 'block',
    },
    rootEditMode: {
      display: 'block',
    },
    rootList: {
      marginTop: '1rem',
      borderTop: `1px solid ${getGradientColor('gray', 20)}`,
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '100%',
      gap: '1rem',
    },
  }),
);

export const useStylesWizardSteps = makeStyles(() =>
  createStyles({
    root: {
      paddingTop: '1rem',
    },

    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '100%',
      gap: '1rem',
    },
  }),
);

export default useStyles;
