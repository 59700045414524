import moment from 'moment-timezone';
import { Event, SlotInfo } from 'react-big-calendar';

import { AvailabilitySlot, MeetingBoundaries, SelectionAction, SelectionMode } from './types';

export const DEFAULT_STEP = 15;
const NUM_OF_MILLISECONDS_IN_A_MINUTE = 1000 * 60;

export const MIN_DATE_OFFSET = 2;
export const MAX_DATE_OFFSET = 9;

export const getPeriodsForMeetTheExpertAvailability = (data: MeetingBoundaries): Event[] => {
  const { timezone, minDate, maxDate } = data;
  const minDateUtc = moment.utc(minDate);
  const minDateUtcStartTime = minDateUtc.clone().tz(timezone).hours(0).minutes(0).seconds(0).milliseconds(0);
  const minDateUtcEndTime = minDateUtc.clone().tz(timezone).hours(23).minutes(59).seconds(59).milliseconds(0);

  const diff = Math.ceil(moment.utc(maxDate).diff(moment.utc(minDate), 'days', true));
  const days = Array.from(Array(diff).keys());

  return days.map((value) => ({
    start: minDateUtcStartTime.utc().clone().add(value, 'day').toDate(),
    end: minDateUtcEndTime.utc().clone().add(value, 'day').toDate(),
  }));
};

export const getPeriodsForMeetTheExpertSlotsSelection = (data: MeetingBoundaries): Event[] => {
  const { initialSelectionBoundaries, minDate, maxDate } = data;

  if (!initialSelectionBoundaries?.length) {
    return getPeriodsForMeetTheExpertAvailability(data);
  }

  return (initialSelectionBoundaries || [])
    .filter(({ start }) => (minDate ? start >= minDate : true))
    .filter(({ end }) => (maxDate ? end <= maxDate : true));
};

const getMaxDateForSlot = (startDate: Date, timezone: string): Date =>
  moment.utc(startDate).clone().tz(timezone).hours(23).minutes(59).seconds(59).milliseconds(0).toDate();

const createAvailabilitySlot = (event: AvailabilitySlot, timezone: string): AvailabilitySlot => {
  const maxDateForTheSlot = getMaxDateForSlot(event.start, timezone);
  // check that the endDate is not in the next day. if so use the max date for the day.
  const endDate = event.end > maxDateForTheSlot ? maxDateForTheSlot : event.end;

  return {
    start: event.start,
    end: endDate,
  };
};

export const mapAllSlotsToEventsSlots = (allSlots: Set<number>, step: number, timezone: string): AvailabilitySlot[] => {
  const tempEvents: AvailabilitySlot[] = [];
  const newEvent: AvailabilitySlot = {} as AvailabilitySlot;
  let prevValue: number | null = null;

  // get out if the set is too small
  if (allSlots.size < 1) {
    return tempEvents;
  }

  // const allSlotsArr = Array.from(allSlots);
  const diffInMilliseconds = step * NUM_OF_MILLISECONDS_IN_A_MINUTE;

  allSlots.forEach((value) => {
    // first iteration
    if (prevValue === null) {
      newEvent.start = new Date(value);
      newEvent.end = new Date(value + diffInMilliseconds);
    }

    // We identified a new time slot
    // Pushing the current event into the tempEvents array
    // Set the startDate to currentValue.
    if (prevValue !== null && value - prevValue > diffInMilliseconds) {
      const objToPush = createAvailabilitySlot(newEvent, timezone);
      tempEvents.push(objToPush);

      newEvent.start = new Date(value);
      newEvent.end = new Date(value + diffInMilliseconds);
    } else {
      // Otherwise - we just set it as the end of the slot
      newEvent.end = new Date(value + diffInMilliseconds);
    }

    prevValue = value;
  });

  // when the loop is done - we push the last new event into the tempEvents array
  if (newEvent?.start && newEvent?.end) {
    const objToPush = createAvailabilitySlot(newEvent, timezone);
    tempEvents.push(objToPush);
  }

  return tempEvents;
};

export const getSelectionAction = (
  prevState: Set<number>,
  slotInfo: SlotInfo,
  events: AvailabilitySlot[],
): SelectionAction => {
  let found = false;

  if (prevState.has(slotInfo.start.getTime())) {
    events.forEach((event) => {
      if (!found && event.end.getTime() === slotInfo.start.getTime()) {
        found = true;
      }
    });
    return found ? SelectionAction.ADD : SelectionAction.SUBTRACT;
  }
  return SelectionAction.ADD;
};

export const subtractSlots = (prevState: Set<number>, slotInfo: SlotInfo): Set<number> => {
  const prevSlotsArr = Array.from(prevState);
  const { start, end } = slotInfo;
  const startTime = start.getTime();
  const endTime = end.getTime();

  return new Set<number>(prevSlotsArr.filter((slot) => !(slot >= startTime && slot < endTime)));
};

export const addSlots = (prevState: Set<number>, slotInfo: SlotInfo): Set<number> => {
  const loopSize = slotInfo.slots.length - 1;
  const newDates: Array<number> = [];

  slotInfo.slots.forEach((date, index) => {
    if (index < loopSize) {
      newDates.push(date.getTime());
    }

    if (date.getTime() !== slotInfo.end.getTime()) {
      newDates.push(date.getTime());
    }
  });

  return new Set([...Array.from(prevState), ...newDates].sort());
};

export const getSelectionBoundaries = (mode: SelectionMode, data: MeetingBoundaries): Event[] => {
  switch (mode) {
    case SelectionMode.availability:
      return getPeriodsForMeetTheExpertAvailability(data);
    case SelectionMode.slots:
      return getPeriodsForMeetTheExpertSlotsSelection(data);
    default:
      return [];
  }
};

export const getEventsAsSlots = (events: Event[], step: number, inclusive = false): Set<number> => {
  const eventsAsSlots = new Set<number>();

  events.forEach((backgroundEvent) => {
    let currSlot = backgroundEvent.start?.getTime() || 0;
    const endTime = backgroundEvent.end?.getTime() || 0;

    while (currSlot && currSlot < endTime) {
      eventsAsSlots.add(currSlot);
      currSlot += step * NUM_OF_MILLISECONDS_IN_A_MINUTE;
    }
    // end time is a special case (if it's the end of the day - 23:59:59.000)
    if (inclusive) {
      eventsAsSlots.add(endTime);
    }
  });

  return eventsAsSlots;
};

export const validateMeetingBoundaries = (meetingBoundariesAsSlots: Set<number>, slotInfo: SlotInfo): boolean => {
  let result = true;

  slotInfo.slots
    .filter((slot) => slot !== slotInfo.end)
    .forEach((slot) => {
      if (result && !meetingBoundariesAsSlots.has(slot.getTime())) {
        result = false;
      }
    });

  return result;
};

export const mapInitialEventsToAllSlots = (initialEvents: AvailabilitySlot[], step: number): Set<number> => {
  const allSlots = new Set<number>();
  const stepSize = step * NUM_OF_MILLISECONDS_IN_A_MINUTE;

  (initialEvents || []).forEach(({ start, end }) => {
    let currValue = start.getTime();
    const endDate = end.getTime();

    while (currValue < endDate) {
      allSlots.add(currValue);
      currValue += stepSize;
    }
  });

  return allSlots;
};

export const hasEventWithLessThanMinimumSlotDuration = (
  events: AvailabilitySlot[],
  requiredDurationInMinutes = 30,
): boolean => events.some(({ start, end }) => moment(end).diff(moment(start), 'minutes') < requiredDurationInMinutes);

export const getScrollToTime = (
  mode: SelectionMode,
  minDate: Date,
  timezone: string,
  selectionBoundaries: Event[],
): Date => {
  let scrollToTime = new Date();

  if (mode === SelectionMode.slots && selectionBoundaries?.length) {
    const minDateFromBoundaries = selectionBoundaries?.[0]?.start;
    if (minDateFromBoundaries) {
      scrollToTime = moment.utc(minDateFromBoundaries).clone().tz(timezone).hours(moment().hours()).toDate();
    }
  }

  if (mode === SelectionMode.availability && minDate) {
    scrollToTime = moment.utc(minDate).clone().tz(timezone).hours(moment().hours()).toDate();
  }

  return scrollToTime;
};

export const getMinMaxDatesByMode = (
  mode: SelectionMode,
  timeZone: string,
): {
  minDate: Date;
  maxDate: Date | undefined;
} => {
  let minDate = moment().toDate();
  let maxDate;

  if (mode === SelectionMode.availability) {
    const minDateUtc = moment.utc().add(MIN_DATE_OFFSET, 'day');
    const maxDateUtc = moment.utc().add(MAX_DATE_OFFSET, 'day');

    minDate = minDateUtc.clone().tz(timeZone).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
    maxDate = maxDateUtc.clone().tz(timeZone).hours(23).minutes(59).seconds(59).milliseconds(0).toDate();
  }

  if (mode === SelectionMode.slots) {
    const maxDateUtc = moment.utc().add(MAX_DATE_OFFSET, 'day');
    maxDate = maxDateUtc.clone().tz(timeZone).hours(23).minutes(59).seconds(59).milliseconds(0).toDate();
  }

  return { minDate, maxDate };
};
