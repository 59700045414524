/* eslint-disable func-names */
import { InternalTeam, InternalTeamType, Project } from '@mayple/types';

const getProjectInternalTeamGrowthStrategists = (project: Partial<Project>): InternalTeam[] => {
  const { internalTeams } = project || {};

  return (internalTeams || ([] as InternalTeam[])).filter(
    ({ internalTeamType }) => internalTeamType === InternalTeamType.GROWTH_STRATEGIST,
  );
};

export const getProjectMainGrowthStrategist = (project: Partial<Project> | null | undefined): InternalTeam | null => {
  const { mainGrowthStrategistId, mainGrowthStrategist } = project || ({} as Project);

  if (mainGrowthStrategistId && mainGrowthStrategist != null) {
    return mainGrowthStrategist;
  }

  return null;
};

const checkProjectHasMainGrowthStrategist = (project: Partial<Project>) =>
  getProjectMainGrowthStrategist(project) !== null;

export const getProjectMainMarketingConsultant = (project: Partial<Project>): InternalTeam | null => {
  const { mainMarketingConsultantId, mainMarketingConsultant } = project || ({} as Project);

  if (mainMarketingConsultantId && mainMarketingConsultant != null) {
    return mainMarketingConsultant;
  }

  return null;
};

const checkProjectHasMainMarketingConsultant = (project: Partial<Project>) =>
  getProjectMainMarketingConsultant(project) !== null;

const checkHasInternalTeamGSConnected = (project: Partial<Project>) => {
  const projectInternalTeamGS = getProjectInternalTeamGrowthStrategists(project);
  return projectInternalTeamGS?.length > 0;
};

/**
 * This is the actual logic function (that needs to be exposed for the test)
 * @param project
 */
export const checkHasAssignedGS = (project: Partial<Project> | null | undefined): boolean => {
  if (!project) {
    return false;
  }

  const hasInternalTeamGSConnected = checkHasInternalTeamGSConnected(project);
  const hasMainGrowthStrategist = checkProjectHasMainGrowthStrategist(project);
  return hasInternalTeamGSConnected && hasMainGrowthStrategist;
};

/**
 * This is the actual logic function (that needs to be exposed for the test)
 * @param project
 */
export const checkHasAssignedMC = (project: Partial<Project> | null | undefined): boolean => {
  if (!project) {
    return false;
  }

  return checkProjectHasMainMarketingConsultant(project);
};
