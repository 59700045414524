import React from 'react';

import { FileStackFile } from './types';
import { FILE_SIZE_UNITS } from './consts';

/**
 * Formats a number as file size
 *
 * @param size - file size - always in bytes
 * @returns {string|*}
 */
export const formatFileSize = (size: number): string | number => {
  if (Number.isNaN(size)) {
    return size;
  }

  const unit = FILE_SIZE_UNITS.find(({ maxSize }) => maxSize > size);

  if (unit) {
    return `${(size / unit.splitBy).toFixed(unit.splitBy === 1 ? 0 : 1)} ${unit.label}`;
  }

  return size;
};

export const renderSecondary = (
  file: FileStackFile,
  showFileDate: boolean,
  showFileSize: boolean,
): React.ReactElement | null => {
  let secondary = null;
  let fileDate;
  let fileSize;

  if (showFileDate && file.created) {
    fileDate = <span style={{ marginRight: 30 }}>{new Date(file.created).toLocaleDateString()}</span>;
  }

  if (showFileSize && file.size) {
    fileSize = <span>{formatFileSize(file.size)}</span>;
  }

  if (showFileDate || showFileSize) {
    secondary = (
      <span>
        {fileDate}
        {fileSize}
      </span>
    );
  }

  return secondary;
};
