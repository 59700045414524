import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '0.75rem',
    },
    title: {
      width: '100%',
      fontWeight: 'bold',
    },
    tooltip: {},
    tooltipPopper: {
      marginLeft: '0.3rem',
      fontSize: '1em',
    },
    vote: {
      display: 'flex',
      flexDirection: 'row',
      widths: '100%',
      justifyContent: 'flex-start',
      gap: '1rem',
      alignItems: 'flex-end',
    },
    ratingRoot: {},
    ratingLabel: {},
  }),
);

export default useStyles;
