import { makeStyles } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../../app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '0 0.5rem 1rem 0.5rem',
      margin: '0 auto 1rem',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderBottom: `1px solid ${colors.borderLight}`,
    },
    title: {
      color: colors.black_light,
      fontWeight: 'bold',
    },
  }),
);

export default useStyles;
