import {
  AdditionalService,
  CurrencyType,
  MediaType,
  NonPpcMarketingServiceType,
  PackagePresentation,
} from '@mayple/types';

import { ExtendedAdditionalPackageProfile } from '../MultiplePackagesSelector/types';

export const mapExtendedAdditionalPackageProfileToAdditionalService = (
  extendedAdditionalPackageProfile: ExtendedAdditionalPackageProfile,
): AdditionalService | null => {
  if (!extendedAdditionalPackageProfile) {
    return null;
  }

  return {
    uuid: '',
    cost: extendedAdditionalPackageProfile?.cost || 0,
    currency: extendedAdditionalPackageProfile?.currency || CurrencyType.USD,
    description: extendedAdditionalPackageProfile?.description || { contents: '', mediaType: MediaType.TEXT_HTML },
    flavor: extendedAdditionalPackageProfile?.flavor || '',
    recurring: extendedAdditionalPackageProfile?.recurring || false,
    serviceType: extendedAdditionalPackageProfile?.serviceType as unknown as NonPpcMarketingServiceType,
    variableFactorCount: extendedAdditionalPackageProfile?.variableFactorCount || 0,
    variableFactorTitle: extendedAdditionalPackageProfile?.variableFactorTitle || '',
    variableFactorUnit: extendedAdditionalPackageProfile?.variableFactorUnit || '',
    marketingServiceTypeInfo: undefined,
  } as AdditionalService;
};

export const enhanceAdditionalServiceWithMarketingServiceTypeInfo = (
  additionalService: AdditionalService | null | undefined,
  marketingServiceTypeInfo: PackagePresentation | null | undefined,
): AdditionalService | null => {
  if (!additionalService) {
    return null;
  }

  return {
    ...additionalService,
    marketingServiceTypeInfo: marketingServiceTypeInfo ?? ({} as PackagePresentation),
  };
};
