// import { useMemo } from 'react';
// import { AssessmentResponse } from '@mayple/types';
import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { AssignedAssessmentResponsesQuery } from 'growl-graphql/dist/queries/assessment/AssignedAssessmentResponsesQuery';

import useAssessmentFilter from './useAssessmentFilter';
import { ActingEntityAssessmentResponseData, ActingEntityAssessmentResponseVariables, AssessmentFilter } from './types';

const useActingEntityAssessmentResponse = (
  assessmentFilter?: AssessmentFilter,
  queryOptions?: QueryHookOptions,
): ActingEntityAssessmentResponseData => {
  const filter = useAssessmentFilter(assessmentFilter);

  const variables: ActingEntityAssessmentResponseVariables = {
    filter,
  };

  const { data, loading, error, refetch } = useQuery(AssignedAssessmentResponsesQuery.query, {
    variables,
    ...queryOptions,
  });

  // TODO: remove this after BE support for filtering by surveyUniqueCode will be implemented
  // const filteredAssessments = useMemo(
  //   () =>
  //     (data?.assignedAssessmentResponses || []).filter((assessment: AssessmentResponse) =>
  //       assessmentType ? assessment?.surveyUniqueCode === assessmentType : true,
  //     ),
  //   [assessmentType, data?.assignedAssessmentResponses],
  // );

  return {
    assessments: data?.assignedAssessmentResponses,
    loading,
    error,
    refetch,
  };
};

export default useActingEntityAssessmentResponse;
