import {
  CurrencyType,
  DeliverableBlueprint,
  DeliverablesFulfillmentBehavior,
  MarketingServiceType,
  MediaType,
  ProjectPackage,
} from '@mayple/types';
import { ProjectPackageBillablesOverridesFormData } from './types';

export const formName = 'ProjectPackageBillablesOverridesForm';

export const getProjectPackageBillablesOverridesInitialValues = (
  projectPackage: ProjectPackage,
): ProjectPackageBillablesOverridesFormData => {
  const deliverablesBlueprint =
    projectPackage?.deliverablesBlueprintsOverride?.[0] || projectPackage?.package?.deliverablesBlueprints?.[0];

  const { unitType, unitDescription, discountPercentage, unitCost, unitAmount, fulfillmentBehavior, serviceType } =
    deliverablesBlueprint || {};

  return {
    serviceType,
    unitType,
    unitDescription,
    unitCost: {
      value: unitCost,
      currency: CurrencyType.USD,
    },
    unitAmount,
    discountPercentage,
    fulfillmentBehavior,
  };
};

export const mapProjectPackageBillablesOverridesFormDataToDeliverableBlueprint = (
  formData: ProjectPackageBillablesOverridesFormData,
): DeliverableBlueprint => ({
  unitType: formData.unitType,
  unitDescription: {
    mediaType: formData?.unitDescription?.mediaType || MediaType.TEXT_PLAIN,
    contents: formData?.unitDescription?.contents || '',
  },
  discountPercentage: formData?.discountPercentage || 0,
  fulfillmentBehavior: formData?.fulfillmentBehavior || DeliverablesFulfillmentBehavior.MOVE_REMAINING_TO_NEXT_CYCLE,
  serviceType: formData.serviceType || MarketingServiceType.OTHER,
  unitAmount: formData?.unitAmount || 1,
  unitCost: formData?.unitCost?.value || 0,
});
