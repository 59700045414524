import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles';

import { colorPalette, colors } from '../../../app/theme';

// TODO: create custom makeStyles
// const myMakeStyles = (themeOverride) => {
//   return makeStyles(createStyles(themeOverride), { index: 1 })
// }

export const useExamplesStyles = makeStyles(
  createStyles({
    root: {},
    title: {
      padding: '16px 32px',
      marginBottom: 16,
      marginTop: 32,
      fontWeight: 700,
    },
    dialogRoot: {
      display: 'block',
    },
    dialogPaperRoot: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    dialogContent: {
      backgroundColor: 'black',
    },
    dialogImgText: {
      color: 'white',
    },
    dialogImgBtn: {
      color: 'white',
      marginBottom: 16,
    },
  }),
  { index: 1 },
);

export const useOverviewStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      listItem: {
        borderRadius: 8,
        border: '1px solid #534FF2',
        margin: '24px 0',
      },
      linkBtnCtn: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'center',
        },
      },
      linkBtnSubCtn: {
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
      linkBtn: {
        marginBottom: 8,
        // width: '100%',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
      linkBtnHelperText: {
        color: colorPalette.gray[80],
      },
      costBeforeDiscount: {
        color: colorPalette.gray[60],
        // marginBottom: 12,
      },
      costBeforeDiscountTxt: {
        textDecoration: 'line-through',
      },
      costAfterDiscount: {
        // color: colorPalette.blue[60],
        marginBottom: 24,
      },
      recurringText: {
        color: colorPalette.gray[60],
      },
      discountChip: {
        color: colorPalette.green[90],
        backgroundColor: colorPalette.green[20],
      },
      contactUsBtn: {
        textDecoration: 'underline',
      },
      priceCtn: {
        marginTop: 24,
      },
      divider: {
        marginTop: 40,
        marginBottom: 40,
      },
      whatsIncludedTitle: {
        color: colorPalette.blue[100],
      },
      whatsIncludedList: {
        paddingLeft: 24,
        marginBottom: 40,
      },
    }),
  { index: 1 },
);

export const usePlatformsStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        padding: '16px 24px',
      },
      recommendedPlatformsTitle: {
        paddingBottom: 16,
      },
      iconsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(8, 1fr)',
        gridGap: '24px',
        justifyItems: 'start',
        [theme.breakpoints.down('sm')]: {
          gridTemplateColumns: 'repeat(4, 1fr)',
          gridGap: '12px',
        },
      },
    }),
  { index: 1 },
);

export const useContactUsStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        margin: '0 auto',
        maxWidth: 960,
        padding: 24,
        [theme.breakpoints.up('md')]: {},
      },
      title: {
        color: colorPalette.blue[100],
        marginBottom: 32,
      },
      caption: {
        marginTop: 16,
        color: colorPalette.gray[80],
      },
      actionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        justifyContent: 'space-between',
        padding: 0,
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
          justifyContent: 'center',
          gap: '32px',
        },
      },
      btn: {
        width: '100%',
        maxWidth: 300,
        padding: '6px 24px',
        [theme.breakpoints.up('md')]: {
          width: 'auto',
        },
      },
      btnStartIcon: {
        lineHeight: '0.5rem',
      },
      icon: {
        '& path': {
          height: 16,
          stroke: colors.primary,
        },
      },
    }),
  { index: 1 },
);

export const useBadgesStyles = makeStyles(
  (/* theme: Theme */) =>
    createStyles({
      badge: {
        display: 'inline-block',
        width: '100%',
      },
      divider: {
        position: 'relative',
      },
      root: {
        justifyContent: 'center',
        display: 'flex',
        padding: 16,
        gap: '8px',
        position: 'relative',
        flex: '1 1 100%',
        '& $divider': {
          '&:after': {
            content: '""',
            width: 1,
            height: 'calc(100%)',
            backgroundColor: '#FECCB3',
            display: 'block',
            position: 'absolute',
            top: 0,
            marginLeft: '-4px',
          },
          '&:first-child:after': {
            display: 'none',
          },
        },
      },
      badgeText: {},
    }),
  { index: 1 },
);

export const useWhatExpertsDoStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: '56px 16px',
        [theme.breakpoints.up('md')]: {
          padding: '56px 32px',
        },
      },
    }),
  { index: 1 },
);

export const useCommonStyles = makeStyles(
  createStyles({
    lightDiv: {
      backgroundColor: colorPalette.sherry[10],
      borderRadius: 8,
      marginBottom: 16,
    },
    center: {
      textAlign: 'center',
    },
    lightText: {
      color: colorPalette.gray[80],
    },
    darkText: {
      color: colorPalette.sherry[100],
    },
    sectionTitle: {
      textAlign: 'center',
      marginBottom: 72,
    },
    imgContainer: {
      width: '100%',
    },
    noBottomMargin: {
      marginBottom: '0 !important',
    },
  }),
  { index: 1 },
);

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      dialogTitleRoot: {
        padding: '28px 24px',
        [theme.breakpoints.up('sm')]: {
          padding: '32px 24px',
        },
      },
      dialogContentRoot: {
        padding: '0 16px',
        [theme.breakpoints.up('sm')]: {
          padding: '0 32px',
        },
      },
      section: {
        paddingTop: 80,
        paddingBottom: 80,
        maxWidth: 1280,
        margin: '0 auto',
        display: 'flex',
        flex: '1 1 100%',
        width: '100%',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
          gap: '40px',
          flexDirection: 'row',
          flexWrap: 'nowrap',
        },
        [theme.breakpoints.only('xs')]: {
          paddingTop: 56,
        },
      },
      sectionHeader: {
        display: 'none',
      },
      smallSection: {
        marginBottom: 40,
        [theme.breakpoints.up('md')]: {
          maxWidth: 'calc(50% - 40px)',
          flexGrow: 1,
          marginBottom: 0,
        },
        '&:last-child': {
          marginBottom: 0,
        },
      },
      appbar: {
        backgroundColor: 'white',
        boxShadow: '0px 1px 4px rgba(51, 53, 82, 0.12), 0px 1px 6px rgba(51, 53, 82, 0.12)',
      },
      whatExpertsDo: {
        backgroundColor: colorPalette.gray[10],
        transform: 'translateX(-16px)',
        width: 'calc(100% + 32px)',
        [theme.breakpoints.up('sm')]: {
          transform: 'translateX(-32px)',
          width: 'calc(100% + 64px)',
        },
      },
      whyChooseThisPackage: {
        backgroundColor: colorPalette.gray[10],
        transform: 'translateX(-16px)',
        width: 'calc(100% + 32px)',
        [theme.breakpoints.up('sm')]: {
          transform: 'translateX(-32px)',
          width: 'calc(100% + 64px)',
        },
      },
      contactUsContainer: {
        paddingTop: 72,
        paddingBottom: 144,
      },
    }),
  { index: 1 },
);

export default useStyles;
