import { TFunction } from 'i18next';
import { SharedFileCategory, SharedFileCreate } from '@mayple/types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FileUploadDialogProps,
  OnFileUpload,
  FileUploadDialogTabs,
  UseFileStackUploadReturn,
  UseGoogleSlidesInputReturn,
  FileUploadData,
} from './types';
import FileUploadDialogEn from './translations/en/FileUploadDialog.json';
import { googleSlideUrlMimeType, googleSlideUrlRegex, TRANSLATION_NS } from './consts';

import { FILE_STACK_DEFAULT_SOURCES } from '../../../app/consts';
import { handleClientError } from '../../../services/logger';
import { useMuiTabState } from '../../../hooks';
import useI18nLazyBundleLoader from '../../../hooks/useI18nLazyBundleLoader';

export const useGoogleSlidesInput = ({
  onUpload,
  category,
  t,
  filename,
}: {
  onUpload: OnFileUpload;
  t: TFunction;
  category: SharedFileCategory;
  filename?: string;
}): UseGoogleSlidesInputReturn => {
  const [slideUrl, setUrl] = useState<string>('');
  const [touched, setTouched] = useState<boolean>(false);

  const isGoogleSlide = googleSlideUrlRegex.test(slideUrl);

  const onSlideUrlChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (!touched) {
        setTouched(true);
      }

      setUrl((e.target.value || '').trim());
    },
    [touched, setTouched, setUrl],
  );

  const onUploadGoogleSlide = useCallback(() => {
    const sharedFileCreate = {
      name: filename || 'Google Slide',
      description: '',
      mimetype: googleSlideUrlMimeType,
      size: 1024,
      url: slideUrl,
      category,
      isMaypleFile: false,
      uploaderContext: '',
    };

    onUpload?.(sharedFileCreate);
  }, [category, filename, onUpload, slideUrl]);

  return {
    slideUrl,
    googleSlideInputError: touched && !isGoogleSlide ? t('notValidGoogleSlideLinkError') : null,
    onSlideUrlChange,
    onUploadGoogleSlide,
  };
};

export const useFileStackUpload = ({
  onUpload,
  category,
  options,
  filename,
}: {
  onUpload: OnFileUpload;
  category: SharedFileCategory;
  options: Record<string, any>;
  filename?: string;
}): UseFileStackUploadReturn => {
  const onFileUpload = useCallback(
    (filestackResponse: Record<string, any>) => {
      try {
        const sharedFileResult = filestackResponse?.filesUploaded?.[0];

        const sharedFileCreate: SharedFileCreate = {
          name: filename || sharedFileResult?.filename || sharedFileResult?.name || new Date().toLocaleString(),
          description: '',
          mimetype: sharedFileResult.mimetype,
          size: sharedFileResult.size,
          url: sharedFileResult.url,
          category,
          isMaypleFile: false,
          uploaderContext: JSON.stringify(sharedFileResult),
        };

        onUpload?.(sharedFileCreate);
      } catch (e) {
        handleClientError(e);
      }
    },
    [category, filename, onUpload],
  );

  const filestackOptions = {
    accept: ['.pdf'],
    fromSources: FILE_STACK_DEFAULT_SOURCES,
    lang: 'en',
    maxFiles: 1,
    minFiles: 1,
    maxSize: 20 * 1024 * 1024,
    ...options,
  };

  return {
    onFileUpload,
    filestackOptions,
  };
};

export const useFileUpload = (props: FileUploadDialogProps): FileUploadData => {
  const {
    onFileUpload,
    category = SharedFileCategory.NONE,
    defaultTab = FileUploadDialogTabs.GoogleSlides,
    filestackOptions = {},
    filename,
  } = props;

  useI18nLazyBundleLoader(TRANSLATION_NS, FileUploadDialogEn);
  const { t } = useTranslation(TRANSLATION_NS);

  const [tab, setTab] = useMuiTabState<typeof FileUploadDialogTabs>(defaultTab, FileUploadDialogTabs, {
    shouldReportEvent: false,
  });

  const { onFileUpload: onFilestackFileUpload, filestackOptions: filestackOptionsFinal } = useFileStackUpload({
    onUpload: onFileUpload,
    category,
    options: filestackOptions,
    filename,
  });

  const { onSlideUrlChange, onUploadGoogleSlide, slideUrl, googleSlideInputError } = useGoogleSlidesInput({
    onUpload: onFileUpload,
    t,
    category,
    filename,
  });

  return {
    t,
    tab,
    setTab,
    onFilestackFileUpload,
    onSlideUrlChange,
    onUploadGoogleSlide,
    slideUrl,
    googleSlideInputError,
    filestackOptions: filestackOptionsFinal,
  };
};
