import React, { ReactNode } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import TextDisplay from '../../display/TextDisplay';
import { useDeviceInfo } from '../../../hooks';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

interface SectionSubtitleProps {
  subtitle: ReactNode;
  classes?: Record<string, string>;
}

const SectionSubtitle: React.FC<SectionSubtitleProps> = (props) => {
  const classes = useStyles(props);
  const { subtitle } = props;
  const { isMobile } = useDeviceInfo();

  return (
    <TextDisplay variant={isMobile ? 'subtitle2' : 'subtitle1'} className={classes.root}>
      {subtitle}
    </TextDisplay>
  );
};

export default SectionSubtitle;
