import React, { useCallback, useEffect, useState } from 'react';

import { EntitySelectionFilterProps, UseEntitySelectionFilterLogicOutput } from './types';
import { useAccountIntegrations } from '../../../hooks/useCronofyElementToken';
import { getCronofyIntegration } from '../../../../CronofyIntegrationManager/hooks';
import { AutoCompleteOptionType } from '../../../../../../Search/AccountSearchAutoComplete/types';

const useEntitySelectionFilterLogic = (props: EntitySelectionFilterProps): UseEntitySelectionFilterLogicOutput => {
  const { accountType, value, onChange } = props;
  const { enableEntitySearch = false, selectedAccount = null } = value || {};

  const [accountId, setAccountId] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { integrations, loading, error } = useAccountIntegrations(accountId, accountType);

  useEffect(() => {
    setErrorMessage('');

    if (loading || error) {
      return;
    }

    const newCronofyIntegration = getCronofyIntegration(integrations);

    // Validate we have Active Cronofy integration
    if (accountId && !newCronofyIntegration?.id) {
      setErrorMessage('This user has no Cronofy Integration');
    }

    if (!accountId) {
      onChange?.(null, enableEntitySearch);
    } else {
      onChange?.({ id: accountId, accountType }, enableEntitySearch);
    }
  }, [loading, error, integrations, accountId, accountType, enableEntitySearch, onChange]);

  const onEntitySelected = useCallback((newSelected: AutoCompleteOptionType | null) => {
    if (!newSelected) {
      setAccountId(null);
    } else if (newSelected?.value) {
      setAccountId(newSelected.value as number);
    }
  }, []);

  const onEnableEntitySearchChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChange?.(selectedAccount, checked);
    },
    [selectedAccount, onChange],
  );

  return {
    enableEntitySearch,
    onEnableEntitySearchChange,
    selectedAccount,
    onEntitySelected,
    errorMessage,
  };
};

export default useEntitySelectionFilterLogic;
