import React, { FC, useMemo } from 'react';
import { Field } from 'redux-form';

import MultiCheckboxInput from './MultiCheckboxInput';
import {
  validateNonFalsyObject,
  validateNonEmptyObject,
  validateMaxedOutSelection,
} from '../../../../services/validation';
import { LabelValueProps } from '../../../../app/types';

interface WizardMultiCheckboxFieldProps {
  name: string;
  enums: LabelValueProps[];
  maxSelections?: number;
  isRequired?: boolean;
  columns?: number | undefined;
}
/**
 * will create 2 checkboxes in a row, that are multi-choice, add them to redux form
 * @param props
 * @returns {*}
 * @constructor
 */
const WizardMultiCheckboxField: FC<WizardMultiCheckboxFieldProps> = (props) => {
  const { name, enums, isRequired, maxSelections, ...rest } = props;

  const validator = useMemo(
    () =>
      [
        isRequired ? validateNonEmptyObject : undefined,
        isRequired ? validateNonFalsyObject : undefined,
        maxSelections ? validateMaxedOutSelection(maxSelections) : undefined,
      ].filter((validateFunction) => !!validateFunction),
    [isRequired, maxSelections],
  );

  return (
    <div>
      <Field component={MultiCheckboxInput} name={name} enums={enums} validate={validator} {...rest} />
    </div>
  );
};

export default WizardMultiCheckboxField;
