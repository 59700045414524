import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  ExtendedAdditionalPackageProfile,
  MultiplePackagesSelectorData,
  MultiplePackagesSelectorProps,
  SelectedPackagesKey,
} from './types';
import { checkIsSelected, getExtendedAdditionalPackageProfiles, getFilteredPackagesByServiceType } from './logic';

import useNonPPCPackagesData from '../../../../hooks/project/useNonPPCPackages';

const useMultiplePackagesSelectorData = (props: MultiplePackagesSelectorProps): MultiplePackagesSelectorData => {
  const { value, filter, onChange, onCreateNewProject } = props;

  const [selectedPackages, setSelectedPackages] = useState<SelectedPackagesKey[]>(value ?? []);

  const { loading, packages } = useNonPPCPackagesData(true);
  const filteredPackages = useMemo(() => getFilteredPackagesByServiceType(packages, filter), [filter, packages]);
  const extendedPackages: ExtendedAdditionalPackageProfile[] = useMemo(
    () => getExtendedAdditionalPackageProfiles(filteredPackages, filter),
    [filter, filteredPackages],
  );

  const onCreateNewProjectClickHandler = useCallback(
    async () => onCreateNewProject?.(selectedPackages),
    [onCreateNewProject, selectedPackages],
  );

  const onSelectHandler = useCallback((selectedPackage: ExtendedAdditionalPackageProfile) => {
    setSelectedPackages((prevState) => {
      const { offering, flavor, serviceType } = selectedPackage;

      const newSelectedPackage: SelectedPackagesKey = {
        serviceType,
        flavor: flavor || '',
        offering,
      };

      const found = checkIsSelected(prevState || [], newSelectedPackage);

      // if new item and not selected
      if (prevState && !found) {
        return [...prevState, newSelectedPackage];
      }

      // if selected - remove
      if (prevState && found) {
        return prevState.filter(
          (currItem) =>
            !(currItem.serviceType === newSelectedPackage.serviceType && currItem.flavor === newSelectedPackage.flavor),
        );
      }

      return [newSelectedPackage];
    });
  }, []);

  useEffect(() => {
    onChange?.(selectedPackages);
  }, [onChange, selectedPackages]);

  return {
    selectedPackages,
    loading,
    extendedPackages,
    onSelectHandler,
    onCreateNewProjectClickHandler,
  };
};

export default useMultiplePackagesSelectorData;
