import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { ImageMetadata } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

type ImageComponent = 'img' | 'div';

interface PackageImageProps {
  image: ImageMetadata | null;
  thumbnail?: boolean;
  component?: ImageComponent;
}

export const useStyles = makeStyles(
  createStyles({
    root: {},
    asDiv: {
      width: '100%',
      height: 0,
      paddingTop: '56.25%',
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
      backgroundRepeat: 'no-repeat',
    },
    asImage: {
      width: '100%',
      display: 'block',
      margin: '0 auto',
    },
    portrait: {
      height: '100%',
      width: 'auto',
      maxHeight: 'calc(100vh - 4em)',
      maxWidth: '100%',
    },
    landscape: {
      width: '100%',
      height: 'auto',
    },
  }),
  { index: 1 },
);

const getImageUrl = (image: ImageMetadata, thumbnail: boolean) => {
  let url = image?.url;

  if (thumbnail) {
    url = image?.formats?.thumbnail?.url || url;
  }
  return url;
};

const PackageImage: FC<PackageImageProps> = (props) => {
  const { image, thumbnail = false, component = 'Image' } = props;
  const classes = useStyles(props);

  const isPortrait = useMemo(() => {
    const height = image?.formats?.thumbnail?.height || 0;
    const width = image?.formats?.thumbnail?.width || 0;
    return height > width;
  }, [image?.formats?.thumbnail?.height, image?.formats?.thumbnail?.width]);

  if (!image) {
    return null;
  }

  const url = getImageUrl(image, thumbnail);
  const { hash } = image;
  const style = {
    backgroundImage: `url(${url})`,
  };

  if (component === 'div') {
    return <div className={classes.asDiv} style={style} />;
  }

  return (
    <img
      className={classNames(classes.asImage, {
        [classes.landscape]: !isPortrait,
        [classes.portrait]: isPortrait,
      })}
      src={url}
      alt={hash}
    />
  );
};

export default PackageImage;
