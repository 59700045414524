import { useCallback } from 'react';

import {
  useAvailabilityViewerDispatch,
  useAvailabilityViewerState,
} from '../../AvailabilityViewerProvider/AvailabilityViewerContext';
import {
  AvailabilityViewerActionType,
  AvailabilityViewerAction,
} from '../../AvailabilityViewerProvider/availabilityViewerReducer';

const useTimezonePicker = (defaultValue?: string): [string, (timezoneName: string | null) => void] => {
  const { filterData } = useAvailabilityViewerState();
  const dispatch = useAvailabilityViewerDispatch();

  const timezone = defaultValue || filterData?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleChange = useCallback(
    (newTimezone: string | null): void => {
      const action: AvailabilityViewerAction = {
        type: AvailabilityViewerActionType.UPDATE_FILTER,
        payload: {
          timezone: newTimezone,
        },
      };
      dispatch(action);
    },
    [dispatch],
  );

  return [timezone, handleChange];
};

export default useTimezonePicker;
