import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { PackageContext } from './logic';

import RichTextViewer from '../../common/RichTextViewer';
import SimpleExpansionPanel from '../../molecules/SimpleExpansionPanel';
import { getGradientColor } from '../../../app/theme';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      rootContainer: {
        marginBottom: 40,
      },
      root: {
        padding: '12px 24px',
        margin: 0,
        borderRadius: 0,
        borderBottom: `1px solid ${getGradientColor('gray', 30)}`,

        [theme.breakpoints.only('xs')]: {
          padding: '0.75rem 0 0.75rem 0',
        },
      },
      noBorder: {
        borderBottom: 'none',
      },
      accordionSummary: {
        color: getGradientColor('blue', 100),
      },
      answerExpended: {
        color: getGradientColor('blue', 90),
      },
      accordionDetails: {},
      removeIcon: {
        color: getGradientColor('blue', 90),
      },
    }),
  { index: 1 },
);

const PackageFaq: FC = () => {
  const { data } = useContext(PackageContext);
  const faqs = data?.marketingServiceTypeInfo?.faqs || [];
  const classes = useStyles();
  const lastIndex = faqs.length - 1;

  return (
    <div className={classes.rootContainer}>
      {faqs.map(
        // @ts-ignore
        ({ question, answer }, i) => (
          <SimpleExpansionPanel
            key={`PackageFaq-${question.replace(/\s/, '-')}-${i}`}
            summary={question}
            details={<RichTextViewer value={answer} />}
            classes={{
              root: classNames(classes.root, { [classes.noBorder]: i === lastIndex }),
              accordionSummary: classes.accordionSummary,
              answerExpended: classes.answerExpended,
              accordionDetails: classes.accordionDetails,
              removeIcon: classes.removeIcon,
            }}
          />
        ),
      )}
    </div>
  );
};

export default PackageFaq;
