import React, { useCallback, useMemo, useState } from 'react';
import { InternalTeamType, Project, ProjectSpecialSubType, SupervisorType } from '@mayple/types';
import Grid from '@material-ui/core/Grid';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { AddProjectToInternalTeamMutation } from 'growl-graphql/dist/mutations/AddProjectToInternalTeamMutation';
import { RemoveProjectFromInternalTeamMutation } from 'growl-graphql/dist/mutations/RemoveProjectFromInternalTeamMutation';

import { getGrowthStrategistFilter } from './logic';
import { ENTITY_TYPE } from '../../../../app/consts';
import EntityList from '../../../../components/cpanel/components/molecules/EntityList';
import UpdateProjectMainInternalTeamSelect from '../../../../components/cpanel/components/molecules/UpdateProjectMainInternalTeamSelect';

import Section, { SECTION_LAYOUT } from '../../../../../fe_common/client/components/atoms/Section';
import CalloutMessage from '../../../../../fe_common/client/components/common/CalloutMessage';
import { useMutation } from '../../../../../fe_common/client/hooks';
import { TextDisplay } from '../../../../../fe_common/client/components/display';
import { Button } from '../../../../../fe_common/client/components/inputs';
import EntitySearchAutoComplete, {
  AutoCompleteOptionType,
} from '../../../../../fe_common/client/components/Search/EntitiySearchAutoComplete';

import useStyles from './style';
import { useProjectDetailsState } from '../../ProjectDetailsProvider';
import useProjectSupervisorValidation from '../ProjectSupervisorChecker/useProjectSupervisorValidation';

interface ProjectConnectedInternalTeamsProps {
  project: Project;
  title?: string;
  onUpdate?: () => Promise<void>;
  InternalTeamTypes?: InternalTeamType[];
  classes?: Record<string, string>;
}

const ProjectConnectedInternalTeams: React.FC<ProjectConnectedInternalTeamsProps> = (props) => {
  const classes = useStyles(props);

  const { project, title = 'Connect Internal Account', onUpdate, InternalTeamTypes = [] } = props;
  const { supervisor, supervisorType, allowedSupervisorTypes, loadingSupervisor } = useProjectDetailsState();
  const { missingMC, missingGS } = useProjectSupervisorValidation(supervisor, supervisorType, allowedSupervisorTypes);

  const [selectedInternalTeam, setSelectedInternalTeam] = useState<number | null>(null);
  // Controlled input search
  const [inputValue, setInputValue] = React.useState('');

  const { mutate: addProjectToInternalTeam } = useMutation(AddProjectToInternalTeamMutation);
  const { mutate: removeProjectFromInternalTeam } = useMutation(RemoveProjectFromInternalTeamMutation);
  const internalTeams = project?.internalTeams || [];
  const hasInternalTeams = internalTeams.length > 0;
  const isUpSale = project?.isUpSale;
  const growthStrategistFilter = useMemo(() => getGrowthStrategistFilter(InternalTeamTypes), [InternalTeamTypes]);

  const onSelectInternalTeam = (newSelected: AutoCompleteOptionType | null) => {
    if (!newSelected) {
      setSelectedInternalTeam(null);
      setInputValue('');
    } else if (newSelected.value) {
      setSelectedInternalTeam(newSelected.value as number);
    }
  };

  const onInputChange = (_event: any, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const onAddInternalTeamToProjectClick = useCallback(async () => {
    const variables = {
      projectId: project.id,
      internalTeamId: selectedInternalTeam,
    };

    await addProjectToInternalTeam({ variables });
    setSelectedInternalTeam(null);
    setInputValue('');
    await onUpdate?.();
  }, [addProjectToInternalTeam, onUpdate, project.id, selectedInternalTeam]);

  const onRemoveProjectFromInternalTeamClick = useCallback(
    async (entity: any) => {
      const variables = {
        projectId: project.id,
        internalTeamId: entity.id,
      };

      await removeProjectFromInternalTeam({ variables });
      await onUpdate?.();
    },
    [onUpdate, project.id, removeProjectFromInternalTeam],
  );

  return (
    <div>
      <Section
        classes={{ root: classes.root }}
        title="Project team"
        subTitle="The main Growth Strategist and Marketing Consultant"
        layout={SECTION_LAYOUT.ONE_COLUMN}
      >
        <Grid container spacing={3}>
          {allowedSupervisorTypes.includes(SupervisorType.MARKETING_CONSULTANT) && (
            <Grid item xs={12}>
              <UpdateProjectMainInternalTeamSelect
                disabled={
                  loadingSupervisor || (isUpSale && project.specialSubType !== ProjectSpecialSubType.MAILCHIMP_UPSELL)
                }
                internalTeamType={InternalTeamType.MARKETING_CONSULTANT}
                projectId={project.id}
                label="Sales / Marketing Consultant"
                onUpdate={onUpdate}
                error={missingMC}
                helperText={missingMC ? 'Missing Sales / Marketing Consultant' : undefined}
              />
            </Grid>
          )}
          {allowedSupervisorTypes.includes(SupervisorType.GROWTH_STRATEGIST) && (
            <Grid item xs={12}>
              <UpdateProjectMainInternalTeamSelect
                disabled={loadingSupervisor}
                internalTeamType={InternalTeamType.GROWTH_STRATEGIST}
                projectId={project.id}
                label="Growth Strategist"
                onUpdate={onUpdate}
                error={missingGS}
                helperText={missingGS ? 'Missing Growth strategist' : undefined}
              />
            </Grid>
          )}
        </Grid>
      </Section>

      <Section
        classes={{ root: classes.root }}
        title={title}
        subTitle="Select the Internal Accounts that should be connected to the project"
        layout={SECTION_LAYOUT.ONE_COLUMN}
      >
        <div className={classes.searchContainer}>
          <EntitySearchAutoComplete
            entityType={ENTITY_TYPE.INTERNAL_TEAM}
            onSelect={onSelectInternalTeam}
            classes={{ root: classes.entitySearchRoot }}
            inputValue={inputValue}
            onInputChange={onInputChange}
            filterBy={growthStrategistFilter}
          />
          <div className={classes.actionButton}>
            <Button
              label="Connect to Project"
              variant="outlined"
              color="primary"
              disabled={!selectedInternalTeam}
              onClick={onAddInternalTeamToProjectClick}
            />
          </div>
        </div>
        {!hasInternalTeams && (
          <CalloutMessage type="info" message="No internal Accounts are connected to this project." />
        )}
        {hasInternalTeams && (
          <div className={classes.connectedInternalTeams}>
            <TextDisplay variant="h6">Connected Internal accounts</TextDisplay>
            <EntityList
              entities={internalTeams}
              entityType={ENTITY_TYPE.INTERNAL_TEAM}
              action={{
                icon: HighlightOffOutlinedIcon,
                handler: onRemoveProjectFromInternalTeamClick,
              }}
            />
          </div>
        )}
      </Section>
    </div>
  );
};

export default ProjectConnectedInternalTeams;
