/* eslint-disable react/no-array-index-key */
import React, { FC, useContext, useMemo, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

import { useCommonStyles, useExamplesStyles } from './style';
import { PackageContext } from './logic';
import PackageBadges from './common/PackageBadges';
import PackageImage from './common/PackageImage';
import PackageExamplesCarousel from './common/PackageExamplesCarousel';
import { Button } from '../../inputs';
import { TextDisplay } from '../../display';
import { colorPalette } from '../../../app/theme';

const PackageExamples: FC = () => {
  const isIframe = useMemo(() => window !== window.top, []);

  const dialogRef = useRef(null);
  const [imgDialog, setImgDialog] = useState({ open: false, url: '', title: '', subtitle: '', image: null });
  const { data, isSmDown } = useContext(PackageContext);
  const commonClasses = useCommonStyles();
  const classes = useExamplesStyles();
  const { heroImage, badges, title, images } = useMemo(() => {
    const { example = {} } = data?.marketingServiceTypeInfo || {};

    return {
      title: example?.title || '',
      badges: example?.badges || [],
      heroImage: example?.images?.[0] || '',
      images: example?.images?.slice(1) || [],
    };
  }, [data]);

  const onDesktopImageClick = (image: Record<string, any>) => {
    // @ts-ignore // TODO FIX THIS
    setImgDialog({ open: true, image: image?.image || null, title: image.title, subtitle: image.subtitle });
  };

  const onDialogClose = () => {
    setImgDialog({ open: false, url: '', title: '', subtitle: '', image: null });
  };

  return (
    <div>
      {isSmDown && <PackageExamplesCarousel />}
      {!isSmDown && (
        <div>
          <ButtonBase
            style={{ width: '100%' }}
            onClick={() => {
              onDesktopImageClick(heroImage);
            }}
          >
            <PackageImage image={heroImage.image} component="div" />
          </ButtonBase>
        </div>
      )}
      {!isSmDown && title && (
        <div className={classNames(commonClasses.lightDiv, classes.title)}>
          <TextDisplay variant="h6" colorOverride={colorPalette.sherry[100]} align="center">
            {title}
          </TextDisplay>
        </div>
      )}
      {!isSmDown && <PackageBadges divider badges={badges} textType="body1" />}
      {!isSmDown && (
        <Grid container spacing={2}>
          {images.map(
            // @ts-ignore // TODO REMOVE THIS AFTER TYPING IS ADDED
            (image, i) => (
              <Grid item xs={12} md={6} key={`PackageExamples-PackageImage-${i}`}>
                <ButtonBase
                  onClick={() => {
                    onDesktopImageClick(image);
                  }}
                  classes={{ root: commonClasses.imgContainer }}
                >
                  <PackageImage thumbnail image={image.image} />
                </ButtonBase>
              </Grid>
            ),
          )}
        </Grid>
      )}
      <Dialog
        open={imgDialog.open}
        onClose={onDialogClose}
        maxWidth="lg"
        classes={{
          container: classNames({ [classes.dialogRoot]: isIframe }),
          paper: classNames({ [classes.dialogPaperRoot]: isIframe }),
        }}
        ref={dialogRef}
      >
        <DialogContent className={classes.dialogContent}>
          <Button
            onClick={onDialogClose}
            label="close"
            className={classes.dialogImgBtn}
            startIcon={<CloseIcon className={classes.dialogImgText} />}
          />
          {!!imgDialog.image && <PackageImage image={imgDialog.image} component="img" />}
          {!!imgDialog.title && (
            <TextDisplay className={classes.dialogImgText} bold>
              {imgDialog.title}
            </TextDisplay>
          )}
          {!!imgDialog.subtitle && (
            <TextDisplay variant="body2" className={classes.dialogImgText}>
              {imgDialog.subtitle}
            </TextDisplay>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PackageExamples;
