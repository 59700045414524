import React, { useMemo } from 'react';
import { AlternativeEmailAddress } from '@mayple/types';
import AddIcon from '@material-ui/icons/Add';

import { WizardStepsProps } from './types';
import { Button } from '../../../inputs';
import { WizardStepField } from '../../../molecules/WizardStepField';
import { validateUniqueStringInArray } from '../../../../services/validation';

import { useStylesWizardSteps } from './style';

const MAX_ITEMS_ON_LIST = 5;

const AlternativeEmailAddressesToEmailAddressArrayMapper = (aea: AlternativeEmailAddress): string => aea.emailAddress;

const WizardSteps: React.FC<WizardStepsProps> = (props) => {
  const classes = useStylesWizardSteps(props);
  const { formValues, alternativeEmailAddresses, onCancel, submit, invalid } = props;

  const { alternativeEmailAddress } = formValues;
  const hasAlternativeEmailAddress = alternativeEmailAddress && alternativeEmailAddress.length > 0;

  const disableOnMaxItems = alternativeEmailAddresses?.length >= MAX_ITEMS_ON_LIST;

  const extraValidation = useMemo(
    () =>
      validateUniqueStringInArray(
        (alternativeEmailAddresses || []).map(AlternativeEmailAddressesToEmailAddressArrayMapper),
      ),
    [alternativeEmailAddresses],
  );

  const handleOnAddEmailAddress = (event?: React.MouseEvent<HTMLButtonElement>): void => {
    event?.preventDefault?.();
    submit?.();
  };

  const onEnterPress = (event: React.KeyboardEvent): void => {
    if (!disableOnMaxItems && event.key === 'Enter') {
      event.preventDefault();
      handleOnAddEmailAddress();
    }
  };

  return (
    <div className={classes.root}>
      <WizardStepField
        key="editable-field-alternativeEmailAddress"
        name="alternativeEmailAddress"
        type={WizardStepField.types.EMAIL}
        // @ts-ignore
        label="Add email address"
        disabled={disableOnMaxItems}
        onEnterPress={onEnterPress}
        maxLength={256}
        extraValidation={[extraValidation]}
        autoFocus
      />
      <div className={classes.actions}>
        <Button
          label="Add"
          endIcon={<AddIcon />}
          color="primary"
          variant="contained"
          disabled={invalid || !hasAlternativeEmailAddress || disableOnMaxItems}
          onClick={handleOnAddEmailAddress}
        />
        <Button label="Finish" color="primary" variant="outlined" onClick={onCancel} />
      </div>
    </div>
  );
};

export default WizardSteps;
