import React, { useCallback } from 'react';
import { AVAILABILITY_MANAGEMENT } from '../../types';
import {
  useAvailabilityViewerDispatch,
  useAvailabilityViewerState,
} from '../../AvailabilityViewerProvider/AvailabilityViewerContext';
import {
  AvailabilityViewerAction,
  AvailabilityViewerActionType,
} from '../../AvailabilityViewerProvider/availabilityViewerReducer';

const useManagedAvailability = (
  defaultValue?: AVAILABILITY_MANAGEMENT,
): [AVAILABILITY_MANAGEMENT, (event: React.ChangeEvent<HTMLInputElement>) => void] => {
  const { filterData } = useAvailabilityViewerState();
  const dispatch = useAvailabilityViewerDispatch();

  const availabilityManagement =
    defaultValue || filterData?.availabilityManagement || AVAILABILITY_MANAGEMENT.USE_MANAGED_AVAILABILITY;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = (event.target as HTMLInputElement).value as AVAILABILITY_MANAGEMENT;
      const action: AvailabilityViewerAction = {
        type: AvailabilityViewerActionType.UPDATE_FILTER,
        payload: {
          availabilityManagement: newValue,
        },
      };

      dispatch(action);
    },
    [dispatch],
  );

  return [availabilityManagement, handleChange];
};

export default useManagedAvailability;
