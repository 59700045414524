import React, { useCallback, useMemo, useState } from 'react';
import { AssessmentResponse, AssessmentResponseState } from '@mayple/types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Pagination from '@material-ui/lab/Pagination';

import usePagination from './usePagination';
import getTaskListItemData from './logic';
import TaskDialog from '../TaskDialog';

import { SkeletonContainer, TextDisplay } from '../../display';
import QueryErrorMessage from '../../common/QueryErrorMessage';
import CalloutMessage from '../../common/CalloutMessage';
import EntityAvatar from '../../atoms/EntityAvatar';
import { useDialog } from '../../../hooks';
import { AssessmentFilter, useActingEntityAssessmentResponse } from '../../../hooks/assessment';

import useStyles from './style';

const TasksList: React.FC = () => {
  const classes = useStyles();
  const { open, openDialog, closeDialog } = useDialog();

  const { assessments, loading, error, refetch } = useActingEntityAssessmentResponse();

  const [assessmentFilter, setAssessmentFilter] = useState<AssessmentFilter | undefined | null>();

  const { page, totalItems, pageSize, numOfPages, setPageHandler } = usePagination(assessments);

  // const onOpenAllTasksClickHandler = useCallback(() => {
  //   setSelectedSubTask(null);
  //   openDialog();
  // }, [openDialog, setSelectedSubTask]);

  const getTaskClickHandler = useCallback(
    (newSelectedAssessment: AssessmentResponse) => () => {
      setAssessmentFilter({
        projectId: newSelectedAssessment?.projectId,
        assessmentType: newSelectedAssessment?.surveyUniqueCode,
        state: [AssessmentResponseState.ASSESSMENT_AWAITING_ANSWERS],
      });
      openDialog();
    },
    [openDialog, setAssessmentFilter],
  );

  const itemsFilter = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (item: any, index: number) => index >= (page - 1) * pageSize && index < page * pageSize,
    [page, pageSize],
  );

  const onCloseDialogHandler = useCallback(async () => {
    await refetch();
    closeDialog();
  }, [closeDialog, refetch]);

  const finalTaskList = useMemo(() => (assessments || []).filter(itemsFilter), [itemsFilter, assessments]);

  return (
    <div className={classes.root}>
      <SkeletonContainer loading={loading} variant="rect" height={300}>
        {!loading && error && <QueryErrorMessage error={error} message="Error while trying to get tasks" />}
        {!loading && !error && assessments?.length === 0 && (
          <CalloutMessage title="Hoorah! 🎉" message="You don't have any open taks to handle at the moment." />
        )}
        {!loading && !error && (assessments ?? []).length > 0 && (
          <>
            <div className={classes.paginatedListHeader}>
              <div>
                <TextDisplay bold>Total tasks: {totalItems}</TextDisplay>
              </div>
              {/*
              <Button
                label="Open All Tasks"
                onClick={onOpenAllTasksClickHandler}
                variant="outlined"
                color="primary"
                size="small"
              />
              */}
              <Pagination page={page} count={numOfPages} variant="outlined" color="primary" onChange={setPageHandler} />
            </div>
            <List>
              {finalTaskList.map((assessment) => {
                const { primaryText, secondaryText } = getTaskListItemData(assessment);

                return (
                  <ListItem key={assessment.uuid}>
                    <EntityAvatar
                      avatarImageUrl=""
                      primary={primaryText}
                      secondary={secondaryText}
                      classes={{
                        secondary: classes.secondary,
                      }}
                      onClick={getTaskClickHandler(assessment)}
                    />
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
        {open && (
          <TaskDialog
            isOpen={open}
            onClose={onCloseDialogHandler}
            containerMaxWidth="md"
            fullWidth
            assessmentFilter={assessmentFilter}
          />
        )}
      </SkeletonContainer>
    </div>
  );
};

export default TasksList;
