import React, { useCallback } from 'react';
import { ProjectPackageDelayReason } from '@mayple/types';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import { ReportProjectPackageDelayMutation } from 'growl-graphql/dist/mutations/project/projectPackage/ReportProjectPackageDelayMutation';

import { ReportProjectPackageDelayReasonDialogProps, ReportProjectPackageDelayReasonFormData } from './types';
import useProjectPackageDelayReasonTranslations from './useProjectPackageDelayReasonTranslations';
import WizardSteps from './steps';

import { Dialog } from '../../display';
import { WizardForm } from '../../organisms/WizardForm';
import { handleClientError } from '../../../services/logger';
import { useMutation } from '../../../hooks';

const FORM_NAME = 'formReportProjectPackageDelayReason';

const ReportProjectPackageDelayReasonDialog: React.FC<ReportProjectPackageDelayReasonDialogProps> = (props) => {
  const { isOpen, onClose, projectPackageUuid, onSuccessfulSave, overrideTranslations } = props;

  const { t } = useProjectPackageDelayReasonTranslations(overrideTranslations);

  const { mutate: reportProjectPackageDelay, loading: saving, error } = useMutation(ReportProjectPackageDelayMutation);

  const handleFormSubmit: FormSubmitHandler<ReportProjectPackageDelayReasonFormData> = useCallback(
    async (formValuesSubmitted, _dispatch, formProps) => {
      const { delayReasonMessage = '', delayReason } = formValuesSubmitted;

      try {
        const variables = {
          projectPackageUuid,
          delayReasonMessage,
          delayReason: delayReason || ProjectPackageDelayReason.DELAY_REASON_NOT_SPECIFIED,
        };

        await reportProjectPackageDelay({ variables });
        await onSuccessfulSave?.();
        formProps?.destroy?.();
        onClose?.();
      } catch (e) {
        handleClientError(e, 'Error while trying to update package delay reason.');
      }
    },
    [onClose, onSuccessfulSave, projectPackageUuid, reportProjectPackageDelay],
  );

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog
      title={t('dialogTitle')}
      subtitle={t('dialogSubtitle')}
      isOpen={isOpen}
      onClose={onClose}
      containerMaxWidth="sm"
      fullWidth
    >
      <WizardForm
        initialValues={{
          projectPackageUuid,
        }}
        form={`${FORM_NAME}-${projectPackageUuid}`}
        // @ts-ignore
        onSubmit={handleFormSubmit}
        onClose={onClose}
        steps={WizardSteps}
        loading={saving}
        error={error}
        overrideTranslations={overrideTranslations}
      />
    </Dialog>
  );
};

export default ReportProjectPackageDelayReasonDialog;
