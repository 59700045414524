import React from 'react';
import { MarketingServiceType, MarketingSkills } from '@mayple/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import ChipList, { ChipListProps } from '../ChipList';

import { MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS } from '../../../app/enums';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

interface SkillsChipListProps extends Omit<ChipListProps, 'items'> {
  marketingSkills: MarketingSkills[] | null | undefined;
}

const mapMarketingSkillToMarketingServiceType = (marketingSkill: MarketingSkills): MarketingServiceType =>
  marketingSkill.skillType;

const SkillsChipList: React.FC<SkillsChipListProps> = (props) => {
  const { marketingSkills } = props;
  const classes = useStyles(props);

  const skillsUsed = (marketingSkills || []).map(mapMarketingSkillToMarketingServiceType);
  return <ChipList items={skillsUsed} labelsLookup={MARKETING_SKILLS_LABELS_LOOKUP_WITH_ICONS} classes={classes} />;
};

export default SkillsChipList;
