import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors, getGradientColor } from '../../../../app/theme';

const smallIconHeight = '24px';
const bigIconHeight = '48px';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      userSelect: 'none',
      cursor: 'pointer',
      perspective: '200vw',
      width: '100%',
      height: '100%',
      outline: 'none',
      transition: 'box-shadow 0.1s',
    },
    flipAnimation: {
      '&$hovering': {
        '&:hover': {
          boxShadow: 'none',
        },
      },
      '& $flipCardInner': {
        transition: 'transform 0.5s',
        transformStyle: 'preserve-3d',
      },
      '&$hoveringWithBackSide': {
        '&:hover': {
          '& $flipCardInner': {
            borderRadius: 4,
            transform: 'rotateY(180deg)',
            boxShadow: `0 2px 16px ${colors.blackOpacity(0.19)}`,
          },

          '& $selectionIndicator': {
            display: 'none',
          },
        },
      },
      '& $flipCardBack': {
        display: 'block',
        transform: 'rotateY(180deg)',

        '& $label': {
          color: colors.primary,
          textAlign: 'left',
          flexGrow: 1,
        },
      },
    },
    fadeInAnimation: {
      '&$hoveringWithBackSide': {
        '&:hover': {
          '& $flipCardFront': {
            opacity: 0,
            transition: 'opacity 0.45s',
          },
          '& $flipCardBack': {
            opacity: 1,
            transition: 'opacity 0.9s',
          },
        },
      },

      '& $flipCardFront': {
        display: 'block',
        opacity: 1,
        transition: 'opacity 0.9s',
      },
      '& $flipCardBack': {
        display: 'block',
        opacity: 0,
        transition: 'opacity 0.45s',
      },
    },
    flipCardInner: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },

    active: {
      '&:active': {
        transform: 'translateY(2px)',
      },
    },
    hovering: {
      '&:hover': {
        transition: 'box-shadow 0.1s',
        boxShadow: `0 2px 16px ${colors.blackOpacity(0.19)}`,
      },
    },
    hoveringWithBackSide: {},

    flipCardBack: { display: 'none' },

    disabled: {
      background: getGradientColor('gray', 10),
      border: `1px solid ${getGradientColor('gray', 30)}`,
      borderRadius: 4,
      cursor: 'default',

      '& $label': {
        color: getGradientColor('gray', 80),
      },
    },

    flipCardSide: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backfaceVisibility: 'hidden',
      background: colors.whiteOpacity(0.3),
      border: '1px solid #ECE6DF',
      borderRadius: 4,
      boxSizing: 'border-box',
      padding: '16px 8px',
    },

    flipCardFront: {},

    vertical: {
      height: 128,
      '& $flipCardFront': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: '16px 8px',
      },
      '& $flipCardBack': {},
      '& $cardIcon': {
        fontSize: bigIconHeight,
        lineHeight: bigIconHeight,
        height: bigIconHeight,
      },
      '& $selectionIndicator': {
        top: 8,
        right: 8,
        left: 'auto',
      },
      '& $checked': {
        '& $selectionIndicator': {
          top: 7,
          right: 7,
        },
      },
    },
    horizontal: {
      '& $flipCardSide': {
        position: 'relative',
      },
      '& $flipCardFront': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 16,
      },
      '& $label': {
        color: colors.black,
        flexGrow: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      '& $subtitle': {
        flexBasis: '100%',
      },
      '& $withCardIcon': {
        flexWrap: 'nowrap',
        '& $label': {
          textAlign: 'left',
        },
        '& $subtitle': {
          paddingLeft: 32,
        },
        '& $extraDetails': {
          paddingLeft: 32,
        },
      },
      '& $withSubtitle, & $withExtraDetails': {
        flexWrap: 'wrap',
        '& $label, & $subtitle, & $extraDetails': {
          textAlign: 'left',
        },
      },
      '& $withSelectionIndicator': {
        paddingRight: 40,
        '& $label': {
          textAlign: 'left',
        },
      },
      '& $withSubtitle': {
        flexWrap: 'wrap',
      },
      '& $cardIconWrapper': {
        marginRight: 8,
      },
      '& $cardIcon': {
        fontSize: smallIconHeight,
        lineHeight: smallIconHeight,
        height: smallIconHeight,
        verticalAlign: 'top',
      },
      '& $selectionIndicator': {
        right: 16,
        left: 'auto',
      },
      '& $checked': {
        padding: 15,
        paddingRight: 39,
        '& $selectionIndicator': {
          right: 15,
        },
      },
    },
    withSelectionIndicator: {},
    withCardIcon: {},
    withSubtitle: {},
    withExtraDetails: {},
    checked: {
      border: `2px solid ${colors.primary}`,
      borderRadius: 4,
    },
    cardIconWrapper: {},
    cardIcon: {
      color: colors.black,
    },
    label: {
      color: colors.black,
      textAlign: 'center',
    },
    subtitle: {
      color: getGradientColor('gray', 60),
    },
    selectionIndicator: {
      position: 'absolute',
      width: 24,
      height: 24,
      color: getGradientColor('gray', 20),
    },
    selectionIndicatorChecked: {
      color: colors.primary,
    },
    extraDetails: {
      flexBasis: '100%',
    },
    extraDetailsText: {
      color: getGradientColor('gray', 60),
    },
  }),
);

export default useStyles;
