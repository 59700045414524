/* eslint-disable function-paren-newline */
import { FeedLegend, FeedPost, Person } from '@mayple/types';
import groupBy from 'lodash/groupBy';

import { FilterType } from './types';

export const getTeamMembers = (legend: FeedLegend, posts: FeedPost[]): Person[] => {
  const participatingTeamMembers = new Set<string>();

  // collecting all authorUuid from posts into a unique Set
  (posts || []).forEach((post) => {
    // collecting post authors
    participatingTeamMembers.add(post.authorUuid);

    post.comments.forEach((comment) => {
      if (comment != null) {
        participatingTeamMembers.add(comment.authorUuid);
      }
    });
  });

  // return only persons who participated in the feed
  const allPersons = (legend.persons || []).filter((person) => participatingTeamMembers.has(person.uuid));

  const groupedPersons = groupBy(allPersons, 'uuid');

  // return unique entries by selecting the first person from each group
  return (
    Object.entries(groupedPersons)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([key, value]) => value[0])
  );
};

export const getTopicsFromPosts = (posts: FeedPost[]): string[] => {
  const participatingTopics = new Set<string>();

  // collecting all topics from posts into a unique Set
  posts.forEach((post) => {
    // collecting post topics
    participatingTopics.add(post.topic);
  });

  // return only topics which participated in the feed
  return Array.from(participatingTopics);
};

export const getFilterSelectedValues = (filter: Record<string, any>, key: FilterType): Array<any> => filter[key] || [];

export const handleFilterToggle =
  (
    value: string,
    selectedValues: string[],
    onFilterChange: (filterType: FilterType, newValue: string[]) => void,
    filterType: FilterType,
  ): (() => void) =>
  () => {
    const currentIndex = selectedValues.indexOf(value);
    const newChecked = [...selectedValues];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    // Notify Parent
    onFilterChange(filterType, newChecked);
  };

const filterCountReducer = (value: number, acc: number): number => {
  const addedValue = acc && Array.isArray(acc) ? acc.length : 0;

  return value + addedValue;
};

export const getFilterCount = (filter: Record<string, any>): number =>
  (Object.values(filter) || []).reduce<number>(filterCountReducer, 0);
