import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { AdditionalService } from '@mayple/types';
import CheckIcon from '@material-ui/icons/Check';
import { PackagesPresentationQuery } from 'growl-graphql/dist/queries/shared/PackagesPresentationQuery';

import { AdditionalPackageCardProps } from './types';
import {
  enhanceAdditionalServiceWithMarketingServiceTypeInfo,
  mapExtendedAdditionalPackageProfileToAdditionalService,
} from './logic';

import { Button } from '../../../inputs';
import { Card, TextDisplay } from '../../../display';
import RichTextViewer from '../../../common/RichTextViewer';
import { formatCurrencyString } from '../../../../services/utils';
import { TRANSLATION_NS } from '../../../../app/consts';
import { useDialog } from '../../../../hooks';
import PackageMarketingServiceTypeInfoDialog from '../../../package/PackageMarketingServiceTypeInfoDialog';

import useStyles from './style';

const AdditionalPackageCard: React.FC<AdditionalPackageCardProps> = (props) => {
  const classes = useStyles(props);

  const {
    additionalPackage,
    disableSelection = false,
    isSelected = false,
    hideCost = false,
    hideDescription = false,
    disabled = false,
    showLearnMore = false,
    onSelect,
  } = props;

  const { name, description, cost, serviceType, flavor } = additionalPackage;

  const { t } = useTranslation(TRANSLATION_NS, { keyPrefix: 'AdditionalPackageCard' });

  const { open, openDialog, closeDialog } = useDialog();

  const onSelectHandler = useCallback(() => onSelect?.(additionalPackage), [additionalPackage, onSelect]);

  const onLearnMoreClickHandler = () => {
    openDialog();
  };

  const { data } = useQuery(PackagesPresentationQuery.query, {
    variables: {
      serviceType,
      flavor,
    },
    skip: !serviceType || !flavor || !open,
  });

  const serviceToView = useMemo(
    () =>
      enhanceAdditionalServiceWithMarketingServiceTypeInfo(
        mapExtendedAdditionalPackageProfileToAdditionalService(additionalPackage) as AdditionalService,
        data?.packagesPresentation,
      ),
    [additionalPackage, data?.packagesPresentation],
  );

  const hasLearnMoreData = showLearnMore && serviceToView;

  return (
    <div className={classes.root}>
      <Card
        classes={{
          root: classNames(classes.nonPpcCard, {
            [classes.selected]: isSelected,
          }),
        }}
      >
        <Card.Content>
          <TextDisplay align="center" bold color="textSecondary" variant="h6" gutterBottom>
            {name}
          </TextDisplay>
          {!hideCost && (
            <TextDisplay align="center" variant="h5" bold>
              {formatCurrencyString(cost || 0)}
            </TextDisplay>
          )}
          {!hideDescription && description && (
            <RichTextViewer value={description} initialMaxHeight={150} initialMaxHeightThreshold={90} />
          )}
          {hasLearnMoreData && (
            <div className={classes.learnMoreContainer}>
              <Button
                variant="text"
                label={t('learnMore')}
                onClick={onLearnMoreClickHandler}
                classes={{ root: classes.learnMore }}
                disableRipple
                disableFocusRipple
                disableTouchRipple
              />
            </div>
          )}
          {hasLearnMoreData && (
            <PackageMarketingServiceTypeInfoDialog open={open} onClose={closeDialog} service={serviceToView} noCTA />
          )}
        </Card.Content>
        {!disableSelection && (
          <Card.Actions>
            <Button
              startIcon={isSelected && <CheckIcon />}
              className={classNames(classes.selectedServiceBtn, {
                [classes.selectedServiceBtnDisabled]: isSelected,
              })}
              fullWidth
              label={isSelected ? 'Remove' : 'Select'}
              color={isSelected ? 'primary' : 'default'}
              variant={isSelected ? 'contained' : 'outlined'}
              disabled={disabled}
              onClick={onSelectHandler}
            />
          </Card.Actions>
        )}
      </Card>
    </div>
  );
};

export default AdditionalPackageCard;
