import { useCallback } from 'react';

import {
  useAvailabilityViewerDispatch,
  useAvailabilityViewerState,
} from '../../../AvailabilityViewerProvider/AvailabilityViewerContext';
import {
  AvailabilityViewerAction,
  AvailabilityViewerActionType,
} from '../../../AvailabilityViewerProvider/availabilityViewerReducer';

const useAccountsSelectionFilter = (): [string[], (newValue: string[]) => void] => {
  const { filterData } = useAvailabilityViewerState();
  const selectedAccounts = filterData?.selectedAccounts || [];
  const dispatch = useAvailabilityViewerDispatch();

  const handleChange = useCallback(
    (newValue: string[]) => {
      const action: AvailabilityViewerAction = {
        type: AvailabilityViewerActionType.UPDATE_FILTER,
        payload: {
          selectedAccounts: newValue,
        },
      };

      dispatch(action);

      const clearSelectedSlotAction: AvailabilityViewerAction = {
        type: AvailabilityViewerActionType.SLOT_SELECTED,
        payload: null,
      };

      dispatch(clearSelectedSlotAction);
    },
    [dispatch],
  );

  return [selectedAccounts, handleChange];
};

export default useAccountsSelectionFilter;
