import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import errorRed from '@material-ui/core/colors/red';

const useStyles = makeStyles(
  createStyles({
    root: {
      userSelect: 'none',
    },
    error: {
      color: errorRed[500],
      borderColor: errorRed[500],
    },
  }),
);

export default useStyles;
