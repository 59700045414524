import { AssessmentResponse, AssessmentResponseState, FilterRequest, PaginationRequest } from '@mayple/types';
import { ApolloError, ApolloQueryResult, OperationVariables } from 'apollo-client';

export enum SurveyUniqueCode {
  d2BMonthlyMarketerAssessmentByCompany_v1 = 'd2BMonthlyMarketerAssessmentByCompany_v1',
  mailchimpHealthCheckAssessmentByCompany_v1 = 'mailchimpHealthCheckAssessmentByCompany_v1',
  outbrainHealthCheckAssessmentByCompany_v1 = 'outbrainHealthCheckAssessmentByCompany_v1',
  postProjectMatchOfferMarketerAssessment_v1 = 'postProjectMatchOfferMarketerAssessment_v1',
}

export type AssessmentFilter = {
  state?: [AssessmentResponseState];
  assessmentType?: SurveyUniqueCode | string | null | undefined;
  projectId?: number | null | undefined;
};

export type ActingEntityAssessmentResponseVariables = {
  pagination?: PaginationRequest | undefined;
  filter?: FilterRequest | undefined;
};

export type ActingEntityAssessmentResponseData = {
  assessments: AssessmentResponse[] | null | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (
    variables?: OperationVariables | ActingEntityAssessmentResponseVariables | undefined,
  ) => Promise<ApolloQueryResult<any>>;
};
