import React from 'react';
import { DeleteFeedPostMutation } from 'growl-graphql/dist/mutations/DeleteFeedPostMutation';
import { DeleteFeedPostCommentMutation } from 'growl-graphql/dist/mutations/DeleteFeedPostCommentMutation';

import { useFeedDispatch, useFeedState } from '../FeedContext';
import { ConfirmDialog } from '../../dialogs/ConfirmDialog';
import useMutation from '../../../hooks/useMutation';
import { FeedActionType } from '../feedReducer';
import { FEED_MEMBERS, FeedMemberType } from '../types';

interface DeleteFeedMemberDialogProps {
  open: boolean;
  onDeleteFeedMemberSuccess?: () => void;
  onDeleteFeedMemberFail?: (errorMessage: string) => void;
}

const deleteMapper = {
  [FEED_MEMBERS.POST]: {
    deleteMutationClass: DeleteFeedPostMutation,
    deleteMessage: 'Are you sure you want to delete this post?',
    uuidKey: 'postUuid',
  },
  [FEED_MEMBERS.COMMENT]: {
    deleteMutationClass: DeleteFeedPostCommentMutation,
    deleteMessage: 'Are you sure you want to delete this comment?',
    uuidKey: 'commentUuid',
  },
};

const getDeleteMapper = (
  feedMemberType?: FeedMemberType,
):
  | {
      deleteMutationClass: any;
      deleteMessage: string;
      uuidKey: string;
    }
  | Record<string, any> => {
  if (!feedMemberType) {
    return {};
  }

  return deleteMapper[feedMemberType];
};

const DeleteFeedMemberDialog: React.FC<DeleteFeedMemberDialogProps> = (props) => {
  const { open, onDeleteFeedMemberSuccess, onDeleteFeedMemberFail } = props;
  const { selectedFeedMember } = useFeedState();
  const feedMemberType = selectedFeedMember?.feedMemberType;
  const selectedFeedMemberUuid = selectedFeedMember?.feedMemberUuid;
  const dispatch = useFeedDispatch();

  const { deleteMessage = '', deleteMutationClass = null, uuidKey = '' } = getDeleteMapper(feedMemberType);

  const { mutate: deleteFeedMember } = useMutation(deleteMutationClass);

  if (!selectedFeedMemberUuid) {
    return null;
  }

  const onConfirmDialogApprove = async () => {
    if (!uuidKey) {
      return;
    }

    const variables = {
      [uuidKey as string]: selectedFeedMemberUuid,
    };

    try {
      // TODO: Use optimistic update
      await deleteFeedMember({ variables });
    } catch (e) {
      // TODO: call dispatch with 'ON_DELETE_POST_SUCCEED'
      if (typeof onDeleteFeedMemberFail === 'function') {
        onDeleteFeedMemberFail(`Error occurred while trying to delete ${feedMemberType?.toString().toLowerCase()}`);
      }
    }

    // TODO: call dispatch with 'ON_DELETE_POST_SUCCEED'
    if (typeof onDeleteFeedMemberSuccess === 'function') {
      onDeleteFeedMemberSuccess();
    }

    dispatch({ type: FeedActionType.CLOSE_DELETE_DIALOG });
    dispatch({ type: FeedActionType.SET_SELECTED_FEED_MEMBER, payload: null });
  };

  const onConfirmDialogCancel = () => {
    dispatch({ type: FeedActionType.CLOSE_DELETE_DIALOG });
    dispatch({ type: FeedActionType.SET_SELECTED_FEED_MEMBER, payload: null });
  };

  return (
    <ConfirmDialog
      open={open}
      title="Please confirm"
      content={deleteMessage}
      onConfirm={onConfirmDialogApprove}
      onCancel={onConfirmDialogCancel}
    />
  );
};

export default DeleteFeedMemberDialog;
