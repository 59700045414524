import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    uploadButton: {
      whiteSpace: 'nowrap',
    },
  }),
);

export default useStyles;
