import React, { FC, useEffect, useState } from 'react';
import { CronofyAvailabilityProfiles } from '@mayple/types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { AvailabilityRulesOptions, CronofyAvailabilityProfilesKeysLabelsLookup } from './types';
import useCronofyElementToken from './hooks/useCronofyElementToken';
import useAvailabilityRulesConfiguration from './hooks/useAvailabilityRulesConfiguration';
import { AVAILABILITY_RULES_PROFILES_LABELS_LOOKUP, Texts } from './constants';
import CronofyAvailabilityRules from '../CronofyAvailabilityRules';
import { AvailabilityRulesHandler } from '../types/cronofyTypes';
import { RefreshSpinner } from '../../../atoms';
import CalloutMessage from '../../../common/CalloutMessage';
import { useMuiTabState } from '../../../../hooks';

import useStyles from './style';
import './style.css';

export interface AvailabilityRulesInitializerProps {
  initOptions?: AvailabilityRulesOptions;
  onAvailabilityRuleSaved?: AvailabilityRulesHandler;
  onAvailabilityRuleEdited?: AvailabilityRulesHandler;
  onAvailabilityRuleNotFound?: AvailabilityRulesHandler;
  availabilityRulesProfiles?: CronofyAvailabilityProfiles[];
  classes?: Record<string, string>;
}

/**
 * This component is our abstraction over CronofyAvailabilityRules component
 * It initialize the Element Token that is needed to communicate with Cronofy
 * And sets some of our default styles and values
 *
 * @param props
 * @constructor
 */
const AvailabilityRulesInitializer: FC<AvailabilityRulesInitializerProps> = (props) => {
  const classes = useStyles(props);
  const { availabilityRulesProfiles = [CronofyAvailabilityProfiles.work_hours] } = props;
  const { isLoading, elementToken, error } = useCronofyElementToken();
  const [availabilityProfile, setAvailabilityProfile] = useState<CronofyAvailabilityProfiles>(
    CronofyAvailabilityProfiles.work_hours,
  );
  const cronofyAvailabilityRulesOptions = useAvailabilityRulesConfiguration(props, elementToken, availabilityProfile);

  const [selectedTab, setSelectedTab] = useMuiTabState(
    CronofyAvailabilityProfiles.work_hours,
    CronofyAvailabilityProfiles,
  );

  useEffect(() => setAvailabilityProfile(selectedTab as CronofyAvailabilityProfiles), [selectedTab]);

  return (
    <div>
      {isLoading && !error && (
        <div className={classes.spinnerWrapper}>
          <RefreshSpinner linear />
        </div>
      )}
      {!isLoading && error && <CalloutMessage type="error" message={Texts.errorMessage} />}
      {!isLoading && !error && cronofyAvailabilityRulesOptions && (
        <>
          {availabilityRulesProfiles?.length > 1 && (
            <Tabs
              value={selectedTab}
              onChange={setSelectedTab}
              variant="fullWidth"
              scrollButtons="off"
              color="primary"
              classes={{ root: classes.tabsRoot }}
            >
              {(availabilityRulesProfiles || []).map((value) => (
                <Tab
                  key={`AvailabilityRulesProfiles-tabs-${value}`}
                  label={
                    (AVAILABILITY_RULES_PROFILES_LABELS_LOOKUP as CronofyAvailabilityProfilesKeysLabelsLookup)[value]
                  }
                  value={value}
                />
              ))}
            </Tabs>
          )}
          <CronofyAvailabilityRules options={cronofyAvailabilityRulesOptions} />
        </>
      )}
    </div>
  );
};

export default AvailabilityRulesInitializer;
