import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: colors.site_background_light,
    },
    dialogTitle: {
      padding: 0,
      paddingTop: 64,
    },
    container: {
      maxWidth: 800,
      margin: '64px auto',
      padding: '0 12px',
    },
    printContainer: {
      display: 'none',
      background: colors.site_background_light,
    },
    avatar: {
      width: 60,
      height: 60,
      marginRight: 24,
    },
    company: {
      margin: '32px 0',
    },
    basicBriefItem: {
      margin: '16px 0',
      '&:first-child': {
        marginTop: 0,
      },
    },
    panelSummary: {
      padding: 24,
    },
    panelRoot: {
      borderRadius: '16px !important',
      boxShadow: 'none',
      marginBottom: 24,
      '&:before': {
        background: 'none',
      },
    },
    briefAnswerWrapper: {
      breakInside: 'avoid-page',
    },
    divider: {
      display: 'block',
      margin: '32px 0',
      // backgroundColor: colors.whiteOpacity(0.75),
    },
  }),
);

export default useStyles;
