import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import FeedPost from '../FeedPost';
import { svgs } from '../../../app/svg';

function FeedPostDemo({ legend }) {
  const demoPostData = useMemo(
    () => ({
      uuid: 'efa4f89358ac11ebae5d0242ac110006',
      authorUuid: 'e620039358ac11ebae5d0242ac110006',
      content: {
        mediaType: 'TEXT_HTML',
        contents:
          '<p><h3>Welcome to Insights!</h3></p>' +
          "<p>This is where you'll find weekly updates from your marketing team of experts " +
          'and your Mayple Marketing Supervisor about your current campaigns and any new opportunities. ' +
          'Stay tuned!</p>',
      },
      actionItems: [],
      topic: 'GENERAL',
      comments: [],
      attachedFiles: [],
      reactions: [],
      permissions: {
        canEdit: false,
        canDelete: false,
      },
      deleted: false,
      published: new Date().toISOString(),
      updated: new Date().toISOString(),
      lastActivityDate: new Date().toISOString(),
    }),
    [],
  );

  const demoLegend = useMemo(
    () => ({
      organizations: {
        '933970ab11e244bebfdb3579c4c3b99b': {
          companyId: 6,
          marketerId: null,
          name: 'Mayple',
          photoUrl: svgs.maypleAvatar,
          type: null,
          uuid: '933970ab11e244bebfdb3579c4c3b99b',
        },
        ...legend?.organizations,
      },
      persons: {
        e620039358ac11ebae5d0242ac110006: {
          name: 'Mayple',
          organizationUuid: '933970ab11e244bebfdb3579c4c3b99b',
          photoUrl: svgs.maypleAvatar,
          title: null,
          uuid: 'e620039358ac11ebae5d0242ac110006',
        },
        ...legend?.persons,
      },
    }),
    [legend],
  );

  return (
    <FeedPost
      viewerPersonUuid="e620039358ac11ebae5d0242ac110006"
      enableReactions={false}
      enableComments={false}
      post={demoPostData}
      legend={demoLegend}
    />
  );
}

FeedPostDemo.propTypes = {
  legend: PropTypes.object.isRequired,
};

export default FeedPostDemo;
