import { FilterRequest, InternalTeam, PaginationRequest } from '@mayple/types';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { AdminAccessibleInternalTeamsQuery } from 'growl-graphql/dist/queries/AdminAccessibleInternalTeamsQuery';

export type AdminAccessibleInternalTeamsOptions = {
  internalTeamIds?: number[] | null | undefined;
  withIntegrations?: boolean;
  withWorkingGroups?: boolean;
  queryOptions?: QueryHookOptions;
};

export type AdminAccessibleInternalTeamsVariables = {
  filter?: FilterRequest | undefined;
  pagination?: PaginationRequest | undefined;
  withIntegrations?: boolean;
  withWorkingGroups?: boolean;
};

export type AdminAccessibleInternalTeamsData = {
  internalTeams: InternalTeam[];
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (
    variables?: Record<string, any> | AdminAccessibleInternalTeamsVariables | undefined,
  ) => Promise<ApolloQueryResult<any>>;
};

const useAdminAccessibleInternalTeams = (
  options: AdminAccessibleInternalTeamsOptions,
): AdminAccessibleInternalTeamsData => {
  const { internalTeamIds = null, withIntegrations = false, withWorkingGroups = false, queryOptions } = options;

  const variables: AdminAccessibleInternalTeamsVariables = {
    withIntegrations,
    withWorkingGroups,
  };

  if (internalTeamIds && internalTeamIds?.length > 0) {
    variables.filter = {
      byIds: internalTeamIds,
    };
  }

  const { data, loading, error, refetch } = useQuery(AdminAccessibleInternalTeamsQuery.query, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    // if passing variables to queryOptions it will override the variables values on top
    ...queryOptions,
  });

  return {
    loading,
    error,
    internalTeams: data?.internalTeams,
    refetch,
  };
};

export default useAdminAccessibleInternalTeams;
