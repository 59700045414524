import { useMemo } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ProjectPackageDelayReason } from '@mayple/types';

import ProjectPackageDelayReasonEn from './translations/en/ProjectPackageDelayReason.json';
import useI18nLazyBundleLoader from '../../../hooks/useI18nLazyBundleLoader';
import { LabelValueProps } from '../../../app/types';
import { pairsToLabelLookup, PROJECT_PACKAGE_DELAY_REASON_PAIRS } from '../../../app/enums';
import { getTranslatedLabelValuePairs } from '../../../app/enumHelpers';

export const TRANSLATION_NS = 'ProjectPackageDelayReason';

export type ProjectPackageDelayReasonTranslations = {
  t: TFunction;
  projectPackageDelayReasonPairs: LabelValueProps<ProjectPackageDelayReason>[];
  projectPackageDelayReasonLabelsLookup: Record<string, string>;
};

const useProjectPackageDelayReasonTranslations = (
  overrideTranslations?: TFunction | null | undefined,
): ProjectPackageDelayReasonTranslations => {
  useI18nLazyBundleLoader(TRANSLATION_NS, ProjectPackageDelayReasonEn);
  const { t } = useTranslation(TRANSLATION_NS);

  const enumTranslations: Record<Partial<ProjectPackageDelayReason>, any> = useMemo(() => {
    const enumTranslationsTemp: Record<Partial<ProjectPackageDelayReason>, string> = {} as Record<
      Partial<ProjectPackageDelayReason>,
      string
    >;

    (Object.keys(ProjectPackageDelayReason) || []).forEach((key) => {
      enumTranslationsTemp[key as ProjectPackageDelayReason] =
        typeof overrideTranslations === 'function' ? overrideTranslations(key) : t(key);
    });

    return enumTranslationsTemp;
  }, [overrideTranslations, t]);

  const projectPackageDelayReasonPairs: LabelValueProps<ProjectPackageDelayReason>[] = useMemo(
    () => getTranslatedLabelValuePairs<ProjectPackageDelayReason>(PROJECT_PACKAGE_DELAY_REASON_PAIRS, enumTranslations),
    [enumTranslations],
  );

  const projectPackageDelayReasonLabelsLookup: Record<string, string> = pairsToLabelLookup(
    projectPackageDelayReasonPairs,
  ) as Record<string, string>;

  return {
    t,
    projectPackageDelayReasonPairs,
    projectPackageDelayReasonLabelsLookup,
  };
};

export default useProjectPackageDelayReasonTranslations;
