import React, { useCallback, useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import List from '@material-ui/core/List';

import AccountListItem from './AccountListItem';
import useAvailabilityViewerFilter from '../../hooks/useAvailabilityViewerFilter';
import { useAvailabilityViewerState } from '../../../AvailabilityViewerProvider/AvailabilityViewerContext';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

interface AccountGroupListProps {
  classes?: Record<string, string>;
}

const AccountGroupList: React.FC<AccountGroupListProps> = (props) => {
  const classes = useStyles(props);
  const { selectedAccountsFilterValue, onSelectedAccountsFilterChange } = useAvailabilityViewerFilter();
  const { accountsWithIntegration, selectedSlot } = useAvailabilityViewerState();

  const selectedSlotParticipants: string[] = useMemo(
    () => (selectedSlot?.participants || []).map(({ sub }) => sub),
    [selectedSlot?.participants],
  );

  const onChangeHandler = useCallback(
    (value: string, isChecked: boolean) => {
      let newValue: string[] = [];

      if (isChecked) {
        newValue = Array.from(new Set([...selectedAccountsFilterValue, value]));
      } else {
        newValue = (selectedAccountsFilterValue || []).filter((v) => v !== value);
      }

      onSelectedAccountsFilterChange(newValue);
    },
    [onSelectedAccountsFilterChange, selectedAccountsFilterValue],
  );

  return (
    <div className={classes.root}>
      <List dense className={classes.root}>
        {(accountsWithIntegration || []).map((account) => {
          const isChecked: boolean =
            !!account?.hasActiveIntegration && !!account?.sub && selectedAccountsFilterValue.includes(account.sub);

          const isHighlighted = !!account?.sub && selectedSlotParticipants.includes(account.sub);

          return (
            <AccountListItem
              key={`account-list-item-${account.id}`}
              account={account}
              withSelection
              isChecked={isChecked}
              isHighlighted={isHighlighted}
              onChange={onChangeHandler}
            />
          );
        })}
      </List>
    </div>
  );
};

export default AccountGroupList;
