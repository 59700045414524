import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../app/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    twoColumnsLayout: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: 24,
      gridColumnGap: 24,
      rowGap: 24,
      gridRowGap: 24,
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
      },
    },
    fileListItem: {},
    fileIcon: {
      color: colors.black_lighter,
    },
    deleteIcon: {
      color: colors.black_lighter,
    },
    downloadIcon: {
      color: colors.black_lighter,
    },
    primary: {
      color: colors.black,
    },
    secondary: {
      color: colors.black_lighter,
    },
    pointer: {
      cursor: 'pointer',
    },
    shiftLeft: {
      right: '48px',
    },
    moreIcon: {},
  }),
);

export default useStyles;
