import React, { FC, useEffect, useRef, useState } from 'react';
import Collapse from '@material-ui/core/Collapse';

import CalloutMessage from '../CalloutMessage';
import { CalloutMessageProps } from '../CalloutMessage/types';

import useStyles from './style';

export interface CalloutMessageCollapseProps extends CalloutMessageProps {
  timeToShow?: number;
  onMessageDisappear?: () => void;
}

const CalloutMessageCollapse: FC<CalloutMessageCollapseProps> = (props) => {
  const classes = useStyles(props);
  const { type, message, timeToShow = 2, onMessageDisappear, ...rest } = props;

  const [showMessage, setShowMessage] = useState(() => message !== '');

  const timeoutId = useRef<number>();

  useEffect(() => {
    if (message !== '') {
      setShowMessage(true);

      timeoutId.current = window.setTimeout(() => {
        setShowMessage(false);
        onMessageDisappear?.();
      }, timeToShow * 1000);
    }
    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId.current);
      }
    };
  }, [message, timeToShow, setShowMessage, onMessageDisappear]);

  useEffect(
    () => () => {
      window.clearTimeout(timeoutId.current);
    },
    [],
  );

  return (
    <Collapse
      in={showMessage}
      appear={showMessage}
      onEntered={() => {
        setShowMessage(true);
      }}
      enter
      exit
    >
      <CalloutMessage type={type} message={message} classes={classes} {...rest} />
    </Collapse>
  );
};

export default CalloutMessageCollapse;
