import { useMemo } from 'react';
import { InternalTeam, InternalTeamType } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import { AdminAccessibleInternalTeamsQuery } from 'growl-graphql/dist/queries/AdminAccessibleInternalTeamsQuery';

const useGrowthStrategists = (): InternalTeam[] => {
  const { data, loading } = useQuery(AdminAccessibleInternalTeamsQuery.query, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const growthStrategists = useMemo(() => {
    if (loading) {
      return [] as InternalTeam[];
    }

    return (data?.internalTeams || []).filter(
      (internalTeam: InternalTeam) => internalTeam.internalTeamType === InternalTeamType.GROWTH_STRATEGIST,
    );
  }, [data?.internalTeams, loading]);

  return growthStrategists as InternalTeam[];
};

export default useGrowthStrategists;
