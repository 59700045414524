import { useCallback } from 'react';

import {
  useAvailabilityViewerDispatch,
  useAvailabilityViewerState,
} from '../../AvailabilityViewerProvider/AvailabilityViewerContext';
import {
  AvailabilityViewerAction,
  AvailabilityViewerActionType,
} from '../../AvailabilityViewerProvider/availabilityViewerReducer';

const useOnlyShowAvailabilityFilter = (defaultValue = false): [boolean, (newValue: boolean) => void] => {
  const { filterData } = useAvailabilityViewerState();
  const dispatch = useAvailabilityViewerDispatch();

  const onlyShowAvailability = filterData?.onlyShowAvailability || defaultValue || false;

  const handleChange = useCallback(
    (newValue: boolean) => {
      const action: AvailabilityViewerAction = {
        type: AvailabilityViewerActionType.UPDATE_FILTER,
        payload: {
          onlyShowAvailability: newValue,
        },
      };

      dispatch(action);
    },
    [dispatch],
  );

  return [onlyShowAvailability, handleChange];
};
export default useOnlyShowAvailabilityFilter;
