import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { colors } from '../../../app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    readLinkContainer: {
      paddingBottom: 8,
      width: '100%',
    },
    readMoreLinkContainer: {
      background:
        `linear-gradient(${colors.whiteOpacity(0)}, ${colors.whiteOpacity(0.75)} 20%, ` +
        `${colors.whiteOpacity(0.95)} 40%, ${colors.whiteOpacity(1)} 100%)`,
      position: 'absolute',
      bottom: 0,
      paddingTop: 48,
    },
    readLessLinkContainer: {
      position: 'static',
      paddingTop: 0,
      paddingBottom: 0,
    },
  }),
);

export default useStyles;
