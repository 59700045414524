import React, { createContext, useContext, useMemo, FC } from 'react';

interface IAvailableMeetingSlotSelectorContext {
  timezone?: string;
  minDate?: Date;
  maxDate?: Date;
  eventBoundariesSlots?: Set<number>;
}

interface AvailableMeetingSlotSelectorContextProviderProps {
  value: IAvailableMeetingSlotSelectorContext;
}

const AvailableMeetingSlotSelectorContext = createContext<IAvailableMeetingSlotSelectorContext>({
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

export const AvailableMeetingSlotSelectorContextProvider: FC<AvailableMeetingSlotSelectorContextProviderProps> = (
  props,
) => {
  const { value: defaultValue, ...rest } = props;
  const { maxDate, minDate, timezone } = defaultValue;

  const value = useMemo(() => ({ timezone, minDate, maxDate }), [timezone, minDate, maxDate]);

  return <AvailableMeetingSlotSelectorContext.Provider value={value} {...rest} />;
};

export default function useAvailableMeetingSlotSelectorContext(): IAvailableMeetingSlotSelectorContext {
  return useContext(AvailableMeetingSlotSelectorContext);
}
