import { Answer, AnswerSet, DeepBriefQuestionnaire, Question, QuestionSet } from '@mayple/types';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';

import { BriefQuestionType, EssentialBriefCoverage } from './types';
import { BRIEF_MOTIVATION_STATUS, BRIEF_MOTIVATION_DATA } from './consts';
import { BRIEF_CATEGORY_ESSENTIAL } from '../../app/consts';

export function getCategoryAnswers(briefData: DeepBriefQuestionnaire[]): Record<string, Answer> {
  if (!briefData) {
    return {};
  }

  let answersHash: Record<string, Answer> = {};
  briefData.forEach((briefCategory) => {
    const { answerSet = {} as AnswerSet } = briefCategory;
    const { answers = [] } = answerSet;

    answersHash = {
      ...answersHash,
      ...keyBy(answers, 'questionUuid'),
    };
  });

  return answersHash;
}

const sortQuestionsFromRenderInfo = (q1: Question, q2: Question) =>
  (q1?.renderingInfo?.importantOrder ?? 0) > (q2?.renderingInfo?.importantOrder ?? 0) ? 1 : -1;

export function getCategoryQuestions(
  briefData: DeepBriefQuestionnaire[],
  answers: Record<string, Answer>,
  isLastCategory = false,
): BriefQuestionType[] {
  if (!briefData) {
    return [];
  }

  let categoryQuestions: BriefQuestionType[] = [];

  briefData.forEach((briefCategory) => {
    const { answerSetUuid, category, answerSet = {} as AnswerSet } = briefCategory;
    const { questionSet = {} as QuestionSet } = answerSet;
    const { questions = [] } = questionSet;

    const remappedQuestions = questions.map((q) => {
      const { uuid } = q;
      return {
        ...q,
        answered: !!answers[uuid],
        answerSetUuid,
        category,
        isLastCategory,
      };
    });

    categoryQuestions = [...categoryQuestions, ...remappedQuestions];
  });

  // sort the questions in the category
  categoryQuestions = categoryQuestions.sort(sortQuestionsFromRenderInfo);

  // Marking the first & last question in the Category
  if (categoryQuestions && categoryQuestions.length > 0) {
    categoryQuestions[0].isFirstQuestion = true;
    categoryQuestions[categoryQuestions.length - 1].isLastQuestion = true;
  }

  return categoryQuestions;
}

export const getBriefMotivationStatusByValue = (value: number): BRIEF_MOTIVATION_STATUS => {
  let briefMotivation = BRIEF_MOTIVATION_STATUS.BRIEF_START;

  Object.values(BRIEF_MOTIVATION_STATUS).forEach((briefMotivationStatusKey) => {
    if (
      value >= BRIEF_MOTIVATION_DATA[briefMotivationStatusKey].minValue &&
      value <= BRIEF_MOTIVATION_DATA[briefMotivationStatusKey].maxValue
    ) {
      briefMotivation = briefMotivationStatusKey;
    }
  });

  // console.log('briefMotivation', briefMotivation);

  return briefMotivation;
};

export const checkIsFirstTime = (briefData: DeepBriefQuestionnaire[]): boolean => {
  if (!briefData || !briefData.length) {
    return true;
  }

  return briefData.every((briefCategory) => (briefCategory?.answerSet?.answers || []).length === 0);
};

const getIsLastCategory = (deepBrief: DeepBriefQuestionnaire[], briefCategory: string): boolean => {
  const numOfCategories = deepBrief.length;
  if (numOfCategories === 0) {
    return false;
  }
  const lastCategory = deepBrief[numOfCategories - 1]?.category;
  return briefCategory === lastCategory;
};

/**
 * Returning the filtered brief data, only with questions that are marked as important.
 *
 * @param deepBrief
 * @returns {*}
 * */
function getEssentialBriefCategory(deepBrief: DeepBriefQuestionnaire[]): DeepBriefQuestionnaire[] {
  return deepBrief.map((deepBriefCategory) => ({
    ...deepBriefCategory,
    answerSet: {
      ...deepBriefCategory.answerSet,
      questionSet: {
        ...deepBriefCategory.answerSet?.questionSet,
        questions: (deepBriefCategory.answerSet?.questionSet?.questions || ([] as Question[])).filter(
          ({ isImportant }) => isImportant,
        ),
      },
    },
  }));
}

export function getBriefData(
  deepBrief: DeepBriefQuestionnaire[],
  briefCategory: string,
): {
  briefData: DeepBriefQuestionnaire[];
  answers: Record<string, Answer>;
  questions: BriefQuestionType[];
} {
  let briefData: DeepBriefQuestionnaire[];
  let answers: Record<string, Answer> = {};
  let questions: BriefQuestionType[] = [];

  const isLastCategory = getIsLastCategory(deepBrief, briefCategory);

  if (briefCategory === BRIEF_CATEGORY_ESSENTIAL) {
    briefData = getEssentialBriefCategory(deepBrief);
  } else {
    briefData = deepBrief.filter(({ category }) => category === briefCategory);
  }

  const hasBriefData = briefData && briefData.length > 0;
  if (hasBriefData) {
    answers = getCategoryAnswers(briefData);
    questions = getCategoryQuestions(briefData, answers, isLastCategory);
  }

  return {
    briefData,
    answers,
    questions,
  };
}

export const calcBriefCoverage = (briefData: DeepBriefQuestionnaire[]): number => {
  if (!briefData || !briefData.length) {
    return 0;
  }

  const totalQuestions = briefData.reduce((total, briefCategory) => {
    const questions = get(briefCategory, 'answerSet.questionSet.questions', []);
    return total + questions.length;
  }, 0);

  const totalAnswers = briefData.reduce((total, briefCategory) => {
    const answers = get(briefCategory, 'answerSet.answers', []);
    return total + answers.length;
  }, 0);

  const percentage = totalQuestions > 0 ? (100 * totalAnswers) / totalQuestions : 0;

  // console.log('totalQuestions:', totalQuestions);
  // console.log('totalAnswers:', totalAnswers);
  // console.log('percentage', percentage);

  return percentage;
};

export function calcEssentialBriefCoverage(deepBrief: DeepBriefQuestionnaire[]): EssentialBriefCoverage {
  const { questions, answers } = getBriefData(deepBrief, BRIEF_CATEGORY_ESSENTIAL);
  const totalQuestions = questions.length;
  let totalAnswers = 0;

  questions.forEach(({ uuid }) => {
    if (answers[uuid]) {
      totalAnswers += 1;
    }
  });

  const essentialFinished = totalQuestions === totalAnswers;

  // calculate fill percentage of essential category
  // if there are no questions in essential category - mark it as 100% completion
  const coveragePercentage = totalQuestions > 0 ? (totalAnswers * 100) / totalQuestions : 100;

  return {
    totalQuestions,
    totalAnswers,
    coveragePercentage,
    essentialFinished,
  };
}

export const checkIfShouldFillEssential = (briefData: DeepBriefQuestionnaire[]): boolean => {
  if (!briefData || !briefData.length) {
    return true;
  }
  const { essentialFinished } = calcEssentialBriefCoverage(briefData);

  return !essentialFinished;
};
