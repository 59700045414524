import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colorPalette } from '../../../../../../app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    contentRoot: {
      paddingTop: 0,
    },
    subTitle: {
      fontWeight: 'normal',
    },
    closeButton: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: colorPalette.gray[60],
    },
  }),
);

export default useStyles;
