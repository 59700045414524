import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { validateNonEmptyArray } from '../../../../services/validation';
import CategoriesEditor from '../../../common/CategoriesEditor';

/**
 * Can be used to select sub categories enums with their respective category.
 * Example usage: src/client/containers/marketer/wizards/MarketerCreationWizard/steps.js:582
 * @param props
 * @returns {*}
 * @constructor
 */
export function WizardCategoriesField(props) {
  const { name, isRequired, categoryName, ...rest } = props;

  const validator = useMemo(
    () => (isRequired ? [validateNonEmptyArray(`Please add at least 1 ${categoryName}`)] : []),
    [categoryName, isRequired],
  );

  // console.log('WizardCategoriesField props', props);

  return <Field name={name} component={CategoriesEditor} validate={validator} categoryName={categoryName} {...rest} />;
}

WizardCategoriesField.propTypes = {
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  categoryName: PropTypes.string,
};

WizardCategoriesField.defaultProps = {
  isRequired: false,
  categoryName: 'category',
};
