import React, { FC } from 'react';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { RefreshSpinner } from '../../RefreshSpinner';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 12,
    },
  }),
);

interface SectionContentProps {
  loading?: boolean;
  classes?: Record<string, string>;
}

const SectionContent: FC<SectionContentProps> = (props) => {
  const classes = useStyles(props);

  const { loading = false, children } = props;

  return (
    <Paper square elevation={1} classes={{ root: classes.root }}>
      {loading && <RefreshSpinner />}
      {!loading && children}
    </Paper>
  );
};

export default SectionContent;
