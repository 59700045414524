import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { MultiplePackagesSelectorProps } from './types';
import { DEFAULT_NUMBER_OF_COLUMNS } from './consts';
import { colors, getGradientColor } from '../../../../app/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    packageSkeleton: {
      marginTop: -24,
      margin: '0 auto',
    },
    multiplePackagesSelectorFormControl: {},
    additionalPackageCardContainer: {
      marginTop: '3rem',
      marginBottom: '6rem',
      display: 'grid',
      columnGap: '1rem',
      gridTemplateColumns: `1fr`,
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: (props: MultiplePackagesSelectorProps) =>
          `0.5fr repeat(${props?.numOfColumns || DEFAULT_NUMBER_OF_COLUMNS}, 1fr)`,
      },
    },
    labelCell: {
      paddingTop: '1rem',
      [theme.breakpoints.down('md')]: {
        gridColumn: `1 / -1`,
      },
    },
    divider: {
      gridColumn: `1 / -1`,
    },
    group0: {
      backgroundColor: 'transparent',
    },
    group1: {
      backgroundColor: 'transparent',
    },
    actionsContainer: {
      position: 'sticky',
      display: 'flex',
      left: 0,
      bottom: 0,
      margin: 0,
      marginTop: -90,
      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '2rem',
      padding: '1.5rem 2rem 1.5rem 0',
      backgroundColor: colors.white,
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        padding: '1.5rem',
        flexWrap: 'wrap',
      },
    },
    errorMessage: {
      flexShrink: 0,
      marginLeft: '2rem',
    },
    backButton: {
      margin: '0 16px 0 0 !important',
    },
    nextButton: {
      margin: '0 !important',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    offeringBorder: {
      borderWidth: '1px',
      boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    },
    offering1: {
      borderColor: getGradientColor('blue', 30),
    },
    offering2: {
      borderColor: getGradientColor('green', 30),
    },
    offering3: {
      borderColor: getGradientColor('yellow', 30),
    },
    offering4: {
      borderColor: getGradientColor('sherry', 30),
    },
    offering5: {
      borderColor: getGradientColor('gray', 30),
    },
  }),
);

export default useStyles;
