import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(
  createStyles({
    root: {},
    withLink: {
      cursor: 'pointer',
    },
    avatar: {},
    avatarXs: {
      width: 24,
      height: 24,
    },
    avatarSm: {
      width: 32,
      height: 32,
    },
    avatarMd: {
      width: 40,
      height: 40,
    },
    avatarLg: {
      width: 56,
      height: 56,
    },
    avatarXl: {
      width: 70,
      height: 70,
    },
    primary: {},
    secondary: {},
  }),
);

export default useStyles;
