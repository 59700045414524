import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TRANSLATION_NS } from '../constants';
import useCronofyElementToken from '../hooks/useCronofyElementToken';
import useAvailabilityViewerConfiguration from '../hooks/useAvailabilityViewerConfiguration';
import CronofyAvailabilityViewer from '../../CronofyAvailabilityViewer';
import { AvailabilityRulesHandler } from '../../types/cronofyTypes';
import { RefreshSpinner } from '../../../../atoms';
import CalloutMessage from '../../../../common/CalloutMessage';

import useStyles from '../style';
import './style.css';

interface AvailabilityViewerInitializerProps {
  onAvailabilityRuleNotFound?: AvailabilityRulesHandler;
  classes?: Record<string, string>;
}

/**
 * This component is our abstraction over CronofyAvailabilityViewer component
 * It initializes the Element Token that is needed to communicate with Cronofy
 * And sets some of our default styles and values
 *
 * @param props
 * @constructor
 */
const AvailabilityViewerInitializer: FC<AvailabilityViewerInitializerProps> = (props) => {
  const { onAvailabilityRuleNotFound } = props;

  const classes = useStyles(props);
  const { t } = useTranslation(TRANSLATION_NS);
  const { isLoading, elementToken, members, error } = useCronofyElementToken();

  const cronofyAvailabilityViewerOptions = useAvailabilityViewerConfiguration(
    elementToken,
    members,
    onAvailabilityRuleNotFound,
  );

  return (
    <div>
      {isLoading && !error && (
        <div className={classes.spinnerWrapper}>
          <RefreshSpinner linear />
        </div>
      )}
      {!isLoading && error && <CalloutMessage type="error" message={t('errorMessage')} />}
      {!isLoading && !error && cronofyAvailabilityViewerOptions && (
        <CronofyAvailabilityViewer options={cronofyAvailabilityViewerOptions} />
      )}
    </div>
  );
};

export default AvailabilityViewerInitializer;
