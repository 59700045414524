import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TRANSLATION_NS } from '../constants';
import useCronofyElementToken from '../hooks/useCronofyElementToken';
import useAvailabilityViewerConfiguration, {
  getValidationErrorMessage,
  validateCronofyAvailabilityViewerOptions,
} from '../hooks/useAvailabilityViewerConfiguration';
import {
  useAvailabilityViewerDispatch,
  useAvailabilityViewerState,
} from '../AvailabilityViewerProvider/AvailabilityViewerContext';
import {
  AvailabilityViewerAction,
  AvailabilityViewerActionType,
} from '../AvailabilityViewerProvider/availabilityViewerReducer';
import CronofyAvailabilityViewer from '../../CronofyAvailabilityViewer';
import { AvailabilityRulesHandler } from '../../types/cronofyTypes';
import { RefreshSpinner } from '../../../../atoms';
import CalloutMessage from '../../../../common/CalloutMessage';

import useStyles from '../style';
import './style.css';

interface AvailabilityViewerInitializerProps {
  onAvailabilityRuleNotFound?: AvailabilityRulesHandler;
  onAvailabilityViewerSlotSelected?: AvailabilityRulesHandler;
  classes?: Record<string, string>;
}

/**
 * This component is our abstraction over CronofyAvailabilityViewer component
 * It initializes the Element Token that is needed to communicate with Cronofy
 * And sets some of our default styles and values
 *
 * @param props
 * @constructor
 */
const AvailabilityViewerInitializer: FC<AvailabilityViewerInitializerProps> = (props) => {
  const { onAvailabilityRuleNotFound, onAvailabilityViewerSlotSelected } = props;

  const classes = useStyles(props);
  const { t } = useTranslation(TRANSLATION_NS);
  const { accountsWithIntegration } = useAvailabilityViewerState();
  const dispatch = useAvailabilityViewerDispatch();
  const { isLoading, elementToken, members, error } = useCronofyElementToken();

  // update the members data into accountsWithIntegration
  useEffect(() => {
    if (!isLoading && members) {
      const action: AvailabilityViewerAction = {
        type: AvailabilityViewerActionType.UPDATE_MEMBERS,
        payload: members,
      };

      dispatch(action);
    }
  }, [dispatch, isLoading, members]);

  // Update the selectedAccounts array in the context
  useEffect(() => {
    if (!isLoading && members) {
      const selectedAccounts: string[] = (accountsWithIntegration || [])
        .filter((accountWithIntegration) => accountWithIntegration?.hasActiveIntegration && accountWithIntegration?.sub)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((accountWithIntegration) => accountWithIntegration.sub!)
        .slice(0, 10);

      const updateFilterAction: AvailabilityViewerAction = {
        type: AvailabilityViewerActionType.UPDATE_FILTER,
        payload: {
          selectedAccounts,
        },
      };

      dispatch(updateFilterAction);
    }
  }, [accountsWithIntegration, dispatch, isLoading, members]);

  const cronofyAvailabilityViewerOptions = useAvailabilityViewerConfiguration(
    elementToken,
    onAvailabilityRuleNotFound,
    onAvailabilityViewerSlotSelected,
  );

  const validCronofyAvailabilityViewerOptions = validateCronofyAvailabilityViewerOptions(
    cronofyAvailabilityViewerOptions,
  );

  return (
    <div>
      {isLoading && !error && (
        <div className={classes.spinnerWrapper}>
          <RefreshSpinner linear />
        </div>
      )}
      {!isLoading && error && <CalloutMessage type="error" message={t('errorMessage')} />}
      {!isLoading &&
        !error &&
        cronofyAvailabilityViewerOptions &&
        typeof validCronofyAvailabilityViewerOptions !== 'boolean' && (
          <CalloutMessage type="info" message={getValidationErrorMessage(validCronofyAvailabilityViewerOptions)} />
        )}
      {!isLoading &&
        !error &&
        cronofyAvailabilityViewerOptions &&
        typeof validCronofyAvailabilityViewerOptions === 'boolean' &&
        validCronofyAvailabilityViewerOptions && (
          <CronofyAvailabilityViewer options={cronofyAvailabilityViewerOptions} />
        )}
    </div>
  );
};

export default AvailabilityViewerInitializer;
