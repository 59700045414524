import { ReactionType } from '@mayple/types';
import { MutationHookOptions } from '@apollo/react-hooks/lib/types';

import { getInsightsFeedParticipantFromCache, writeInsightsFeedParticipantToCache } from '../logic';

const getReactToFeedPostMutationOptions = (
  postUuid: string,
  projectId: number | null,
  marketerId: number | null,
  personUuid: string,
): MutationHookOptions => ({
  update: (cache /* , { data } */) => {
    const participant = getInsightsFeedParticipantFromCache(cache, projectId, marketerId);

    if (!participant) {
      return;
    }

    const posts = participant?.insightsFeedMaterializedView?.posts || [];
    const postIndex = posts.findIndex(({ uuid }: { uuid: string }) => uuid === postUuid);

    const { reactions } = participant.insightsFeedMaterializedView.posts[postIndex];
    const postReactionIndex = reactions.findIndex(({ personUuid: pu }: { personUuid: string }) => pu === personUuid);

    // If the user already reacted to the post
    if (postReactionIndex >= 0) {
      reactions.splice(postReactionIndex, 1);
    } else {
      // Add user reaction to the post
      const newReaction = {
        personUuid,
        __typename: 'FeedReaction',
        reactionType: ReactionType.LIKE,
      };

      reactions.push(newReaction);
    }

    writeInsightsFeedParticipantToCache(cache, projectId, marketerId, participant);
  },
  optimisticResponse: {
    reactToFeedPost: {
      __typename: 'EntityOperationResponse',
      result: 'Success',
      success: true,
    },
  },
});

export default getReactToFeedPostMutationOptions;
