import React, { FC, useCallback, useMemo } from 'react';
import { FeedPostCommentPermissions, FeedPostPermissions } from '@mayple/types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { useFeedDispatch } from '../FeedContext';
import { FeedActionType } from '../feedReducer';
import { FeedMemberType } from '../types';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'inline-block',
    },
    menuRoot: {
      minWidth: 150,
    },
  }),
);

interface FeedContextMenuButton {
  feedMemberUuid: string;
  permissions: FeedPostPermissions | FeedPostCommentPermissions;
  feedMemberType: FeedMemberType;
  classes?: Record<string, string>;
}

const FeedContextMenuButton: FC<FeedContextMenuButton> = (props) => {
  const classes = useStyles(props);
  const { feedMemberUuid, feedMemberType, permissions } = props;
  const { canDelete = false } = permissions;
  const dispatch = useFeedDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteDialog = useCallback(() => {
    dispatch({ type: FeedActionType.OPEN_DELETE_DIALOG });

    dispatch({ type: FeedActionType.SET_SELECTED_FEED_MEMBER, payload: { feedMemberType, feedMemberUuid } });
  }, [dispatch, feedMemberType, feedMemberUuid]);

  const contextMenuItems = useMemo(
    () => [
      // {
      //   id:      'edit',
      //   label:   'Edit',
      //   onClick: () => {
      //     // showEditPostDialog(postUuid);
      //   },
      //   show:    permissions.canEdit || false,
      // },
      {
        id: 'delete',
        label: 'Delete',
        onClick: showDeleteDialog,
        show: canDelete || false,
      },
    ],
    [showDeleteDialog, canDelete],
  );

  if (!canDelete) {
    return null;
  }

  return (
    <div className={classes.root}>
      <IconButton size="small" aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleOnClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: classes.menuRoot,
        }}
      >
        {contextMenuItems
          .filter(({ show }) => show)
          .map(({ id, label, onClick }) => (
            <MenuItem
              key={id}
              onClick={() => {
                onClick();
                handleClose();
              }}
            >
              {label}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

export default FeedContextMenuButton;
