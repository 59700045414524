import { useCallback } from 'react';
import { ApolloError } from 'apollo-client';
import omitDeep from 'omit-deep-lodash';
import { useQuery } from '@apollo/react-hooks';
import { FlowConfiguration, FlowConfigurationInput, ProjectStage, StageConfigurationValue } from '@mayple/types';
import { ProjectFlowConfigurationQuery } from 'growl-graphql/dist/queries/ProjectFlowConfigurationQuery';
import { SetProjectFlowConfigurationMutation } from 'growl-graphql/dist/mutations/admin/SetProjectFlowConfigurationMutation';

import useMutation from '../useMutation';

export type FlowConfigurationKey = keyof FlowConfiguration;
export type FlowConfigurationInputKey = keyof FlowConfigurationInput;

export interface ProjectFlowConfigurationMutationData {
  setProjectFlowConfiguration: (options?: any) => Promise<any>;
  loading: boolean;
  error: ApolloError | undefined;
}

export const useProjectFlowConfigurationMutation = (): ProjectFlowConfigurationMutationData => {
  const {
    mutate: setProjectFlowConfiguration,
    loading,
    error,
  } = useMutation(SetProjectFlowConfigurationMutation, {
    successMessage: '',
  });

  return {
    setProjectFlowConfiguration,
    loading,
    error,
  };
};

export const cleanFlowConfiguration = (flowConfiguration: FlowConfiguration): FlowConfigurationInput => {
  const nonFlowConfigurationInputKeys = ['allowDelayedProjectLaunch'];

  const newFlowConfiguration = omitDeep(
    {
      ...flowConfiguration,
    },
    '__typename',
    'hidden',
    ...nonFlowConfigurationInputKeys,
  );

  return newFlowConfiguration as FlowConfigurationInput;
};

const useProjectFlowConfiguration = (
  projectId: number | null | undefined,
): {
  loading: boolean;
  saving: boolean;
  error: ApolloError | undefined;
  refetch: () => Promise<any>;
  stage: ProjectStage | null | undefined;
  flowConfiguration: FlowConfiguration | null | undefined;
  getProjectFlowConfigurationByKey: (flowConfigurationKey: FlowConfigurationKey) => StageConfigurationValue;
  saveProjectFlowConfiguration: (
    flowConfigurationInputKey: FlowConfigurationInputKey,
    newStageConfigurationValue: number,
  ) => Promise<any>;
} => {
  const { data, loading, error, refetch } = useQuery(ProjectFlowConfigurationQuery.query, {
    variables: {
      projectId,
    },
    skip: !projectId,
  });

  const { setProjectFlowConfiguration, loading: saving } = useProjectFlowConfigurationMutation();

  const getProjectFlowConfigurationByKey = useCallback(
    (flowConfigurationKey: FlowConfigurationKey): StageConfigurationValue => {
      if (!!projectId && !loading && !data?.project?.flowConfiguration) {
        throw new Error('flowConfiguration is empty.');
      }

      if (!!projectId && !loading && !data?.project?.flowConfiguration?.[flowConfigurationKey]) {
        throw new Error(
          `Requested key ${flowConfigurationKey} is missing from flowConfiguration. (Check requested flowConfiguration fields).`,
        );
      }

      return data?.project?.flowConfiguration?.[flowConfigurationKey];
    },
    [data?.project?.flowConfiguration, loading, projectId],
  );

  const saveProjectFlowConfiguration = useCallback(
    async (flowConfigurationKey: FlowConfigurationInputKey, newStageConfigurationValue: number): Promise<any> => {
      const flowConfigurationToUpdate: FlowConfigurationInput = cleanFlowConfiguration(
        data?.project?.flowConfiguration,
      );

      if (
        flowConfigurationKey &&
        flowConfigurationToUpdate &&
        flowConfigurationToUpdate[flowConfigurationKey] != null
      ) {
        // @ts-ignore
        flowConfigurationToUpdate[flowConfigurationKey].value = newStageConfigurationValue;
      }

      const variables = {
        projectId,
        flowConfiguration: flowConfigurationToUpdate,
      };

      return setProjectFlowConfiguration({ variables });
    },
    [data?.project?.flowConfiguration, projectId, setProjectFlowConfiguration],
  );

  return {
    loading,
    saving,
    error,
    refetch,
    stage: data?.project?.stage,
    flowConfiguration: data?.project?.flowConfiguration,
    saveProjectFlowConfiguration,
    getProjectFlowConfigurationByKey,
  };
};

export default useProjectFlowConfiguration;
