import { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  IMarketingPlanContext,
  MarketingPlanEventHandlers,
  MarketingPlanProviderInitialValues,
  UseInitMarketingPlanProviderDataReturnValue,
} from './types';
import { getStateDataFromParticipant } from './logic';
import { MarketingPlanActionType, marketingPlanReducer } from './marketingPlanReducer';
import { TEMPLATE_LINK, TRANSLATION_NS } from '../MarketingPlanProgress/consts';
import useMainGrowthStrategist from '../../../hooks/internalTeam/useMainGrowthStrategist';
import useActingAccount from '../../../hooks/useActingAccount';
import useMarketerParticipantData from '../../../hooks/marketer/useMarketerParticipantData';
import usePublicProjectDeepBriefData from '../../../hooks/project/usePublicProjectDeepBriefData';

// Hook to collect all data and place it in the MarketingPlanProgressData
const useInitMarketingPlanProviderData = (
  initialValues: MarketingPlanProviderInitialValues,
): UseInitMarketingPlanProviderDataReturnValue => {
  const { projectId, marketerId } = initialValues;
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const { t } = useTranslation(TRANSLATION_NS, { keyPrefix: 'common' });

  const { accountType, loading: actingAccountLoading, error: actingAccountError } = useActingAccount();

  const {
    project,
    error: projectWithDeepBriefError,
    loading: projectWithDeepBriefLoading,
  } = usePublicProjectDeepBriefData(projectId);

  const {
    participant,
    error: participantError,
    loading: participantLoading,
    refetch: participantRefetch,
  } = useMarketerParticipantData(projectId, marketerId);

  const clearErrorMessages = useCallback(() => {
    setErrorMessages([]);
  }, []);

  const onError = useCallback(
    (errorMessage: string) => {
      setErrorMessages([errorMessage]);
    },
    [setErrorMessages],
  );

  const [initialState] = useState<IMarketingPlanContext>(() => {
    const eventHandlers: MarketingPlanEventHandlers = {
      onError,
      onUploadMarketingPlan: participantRefetch,
      onDeleteMarketingPlan: participantRefetch,
      onApproveMarketingPlanByGS: participantRefetch,
    };

    return {
      ...initialValues,
      isLoading,
      isInitialized,
      errorMessages,
      clearErrorMessages,
      eventHandlers,
      templateLink: TEMPLATE_LINK,
    };
  });

  // handle the isLoading status
  useEffect(() => {
    setIsLoading(actingAccountLoading || projectWithDeepBriefLoading || participantLoading);
  }, [actingAccountLoading, projectWithDeepBriefLoading, participantLoading]);

  /// we will return this
  const [state, dispatch] = useReducer(marketingPlanReducer, initialState);

  useEffect(() => {
    dispatch({ type: MarketingPlanActionType.SET_VALUES, payload: { isLoading, isInitialized, errorMessages } });
  }, [isLoading, isInitialized, errorMessages]);

  const mainGrowthStrategist = useMainGrowthStrategist(project);

  const getStateDataFromQueries = useCallback(() => {
    const participantStateData = getStateDataFromParticipant(participant, t);

    return {
      accountType,
      project,
      mainGrowthStrategist,
      companyName: project?.company?.name || '',
      ...participantStateData,
    };
  }, [accountType, mainGrowthStrategist, participant, project, t]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const newState = getStateDataFromQueries();

    dispatch({
      type: MarketingPlanActionType.SET_VALUES,
      payload: newState,
    });

    setIsInitialized(true);
  }, [isLoading, getStateDataFromQueries]);

  useEffect(() => {
    if (!actingAccountError && !participantError && !projectWithDeepBriefError) {
      return;
    }

    const collectErrors: string[] = [];

    [actingAccountError, participantError, projectWithDeepBriefError].forEach((error) => {
      if (error && error?.message) {
        collectErrors.push(error?.message);
      }
    });

    setErrorMessages(collectErrors);
  }, [actingAccountError, participantError, projectWithDeepBriefError]);

  return [state, dispatch];
};

export default useInitMarketingPlanProviderData;
