import { useCallback } from 'react';
import { CronofyAvailabilityProfiles } from '@mayple/types';

import {
  useAvailabilityViewerDispatch,
  useAvailabilityViewerState,
} from '../../AvailabilityViewerProvider/AvailabilityViewerContext';
import {
  AvailabilityViewerAction,
  AvailabilityViewerActionType,
} from '../../AvailabilityViewerProvider/availabilityViewerReducer';

const useAvailabilityProfiles = (
  defaultValue?: CronofyAvailabilityProfiles[] | null,
): [CronofyAvailabilityProfiles[] | null, (newValue: Record<string, boolean> | undefined) => void] => {
  const { filterData } = useAvailabilityViewerState();
  const dispatch = useAvailabilityViewerDispatch();

  const availabilityProfiles = filterData?.availabilityProfiles || defaultValue || null;

  const handleChange = useCallback(
    (newValue: Record<string, boolean> | undefined): void => {
      const newAvailabilityProfiles: CronofyAvailabilityProfiles[] = [];

      if (newValue) {
        Object.entries(newValue).forEach(([key, value]) => {
          if (value) {
            newAvailabilityProfiles.push(key as CronofyAvailabilityProfiles);
          }
        });
      }

      const action: AvailabilityViewerAction = {
        type: AvailabilityViewerActionType.UPDATE_FILTER,
        payload: {
          availabilityProfiles: newValue === null ? newValue : newAvailabilityProfiles,
        },
      };

      dispatch(action);
    },
    [dispatch],
  );

  return [availabilityProfiles, handleChange];
};

export default useAvailabilityProfiles;
