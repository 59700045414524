import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
    },
    actionsWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '1rem',
    },
  }),
);

export default useStyles;
