import React, { useCallback, useState } from 'react';
import { SharedFileCategory } from '@mayple/types';
import { TFunction } from 'i18next';

import { FILE_STACK_DEFAULT_SOURCES } from '../../../../app/consts';
import { handleClientError } from '../../../../services/logger';
import { OnMarketingPlanUpload, UseGoogleSlidesInputReturn, UseFileStackUploadReturn } from './types';
import { googleSlideUrlMimeType, googleSlideUrlRegex } from './consts';

export const useGoogleSlidesInput = (onUpload: OnMarketingPlanUpload, t: TFunction): UseGoogleSlidesInputReturn => {
  const [slideUrl, setUrl] = useState<string>('');
  const [touched, setTouched] = useState<boolean>(false);

  const isGoogleSlide = googleSlideUrlRegex.test(slideUrl);

  const onSlideUrlChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (!touched) {
        setTouched(true);
      }

      setUrl((e.target.value || '').trim());
    },
    [touched, setTouched, setUrl],
  );

  const onUploadGoogleSlide = useCallback(() => {
    const sharedFileCreate = {
      name: 'Marketing Plan',
      description: '',
      mimetype: googleSlideUrlMimeType,
      size: 1024,
      url: slideUrl,
      category: SharedFileCategory.MARKETING_PLAN,
      isMaypleFile: false,
      uploaderContext: '',
    };

    onUpload(sharedFileCreate);
  }, [onUpload, slideUrl]);

  return {
    slideUrl,
    googleSlideInputError: isGoogleSlide ? null : t('notValidGoogleSlideLinkError'),
    showGoogleSlideInputError: touched && !isGoogleSlide,
    onSlideUrlChange,
    onUploadGoogleSlide,
  };
};

export const useFileStackUpload = (onUpload: OnMarketingPlanUpload): UseFileStackUploadReturn => {
  const onFileUpload = useCallback(
    (filestackResponse: Record<string, any>) => {
      try {
        const sharedFileResult = filestackResponse?.filesUploaded?.[0];

        const sharedFileCreate = {
          name: sharedFileResult?.filename || sharedFileResult?.name || 'Marketing Plan',
          description: '',
          mimetype: sharedFileResult.mimetype,
          size: sharedFileResult.size,
          url: sharedFileResult.url,
          category: SharedFileCategory.MARKETING_PLAN,
          isMaypleFile: false,
          uploaderContext: JSON.stringify(sharedFileResult),
        };

        onUpload(sharedFileCreate);
      } catch (e) {
        handleClientError(e);
      }
    },
    [onUpload],
  );

  const fileStackOptions = {
    accept: ['.pdf'],
    fromSources: FILE_STACK_DEFAULT_SOURCES,
    lang: 'en',
    maxFiles: 1,
    minFiles: 1,
    maxSize: 20 * 1024 * 1024,
  };

  return {
    onFileUpload,
    fileStackOptions,
  };
};
