import { ApolloError } from 'apollo-client';
import { FilterByOperator, Marketer } from '@mayple/types';
import { AdminAccessibleMarketersPagedQuery } from 'growl-graphql/dist/queries/AdminAccessibleMarketersPagedQuery';

import usePagedQuery from '../../../../../hooks/usePagedQuery';

export interface AdminAccessibleMarketersData {
  marketers: Marketer[] | null | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

const useAdminAccessibleMarketersData = (
  marketerIds?: number[],
  withIntegrations = false,
): AdminAccessibleMarketersData => {
  const { data, loading, error } = usePagedQuery(
    AdminAccessibleMarketersPagedQuery.query,
    'marketersPaged',
    {
      withIntegrations,
      filter: {
        by: [
          {
            key: 'id',
            value: String(marketerIds),
            operator: FilterByOperator.IN,
          },
        ],
      },
      pagination: {
        pageSize: 10,
      },
    },
    {
      skip: !marketerIds,
    },
  );

  return {
    marketers: data,
    loading,
    error,
  };
};

export default useAdminAccessibleMarketersData;
