import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../app/theme';

export default makeStyles(() =>
  createStyles({
    root: {
      paddingTop: 0,
      marginTop: 0,
    },
    groupWrapper: {
      display: 'flex',
      width: '100%',
      marginTop: 16,
      backgroundColor: colors.white_darkest,
      padding: 8,
      borderTop: '1px solid',
      borderTopColor: colors.blackOpacity(0.5),
      borderBottom: '1px solid',
      borderBottomColor: colors.blackOpacity(0.12),
      '&:first-child': {
        borderTop: '0px none',
        borderTopColor: 'transparent',
        marginTop: 0,
      },
    },
    groupLabel: {
      textAlign: 'left',
      fontWeight: 'bold',
      textIndent: 8,
    },
    propertyWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      borderBottom: '1px solid',
      borderBottomColor: colors.blackOpacity(0.12),
      padding: 8,
    },
    propertyLabel: {
      textAlign: 'left',
      flexBasis: '33%',
    },
    propertyValue: {
      textAlign: 'right',
      fontWeight: 600,
    },
  }),
);
