import React, { useMemo, useState } from 'react';

const PAGE_SIZE = 5;

interface PaginationData {
  page: number;
  numOfPages: number;
  pageSize: number;
  totalItems: number;
  setPageHandler: (event: React.ChangeEvent<unknown>, newPage: number) => void;
}

const usePagination = (items: unknown[] | null | undefined, pageSize: number = PAGE_SIZE): PaginationData => {
  const totalItems = (items ?? []).length || 0;
  const numOfPages = useMemo(() => Math.ceil(totalItems / pageSize), [pageSize, totalItems]);
  const [page, setPage] = useState<number>(1);
  const setPageHandler = (_event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  return {
    page,
    numOfPages,
    pageSize,
    totalItems,
    setPageHandler,
  };
};

export default usePagination;
