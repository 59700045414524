import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateParticipantSharedFileMutation } from 'growl-graphql/dist/mutations/CreateParticipantSharedFileMutation';
import { RemoveParticipantSharedFileMutation } from 'growl-graphql/dist/mutations/RemoveParticipantSharedFileMutation';
import { ReplaceParticipantSharedFileMutation } from 'growl-graphql/dist/mutations/ReplaceParticipantSharedFileMutation';

import { UseCreateYourMarketingPlanStepReturnValue } from './types';
import { useStepperLogic } from '../../logic';
import { TRANSLATION_NS } from '../../../consts';
import { checkMarketingPlanIsApprovedByCompany } from '../../../logic';
import { useMarketingPlanDispatch, useMarketingPlanState } from '../../../../MarketingPlanProvider';
import { MarketingPlanActionType } from '../../../../MarketingPlanProvider/marketingPlanReducer';
import { OnMarketingPlanDelete, OnMarketingPlanUpload } from '../../../UploadMarketingPlanDialog/types';

import { FileListFile } from '../../../../../molecules/FilesList/types';
import { useDialog, useMutation } from '../../../../../../hooks';
import { MarketingPlanEventHandlers } from '../../../../MarketingPlanProvider/types';
import { reportEvent } from '../../../../../../logic/events';

const LINK_CLICK_LABEL_THE_BRIEF = 'the brief';
const LINK_CLICK_LABEL_THIS_TEMPLATE = 'this template';

const useCreateYourMarketingPlanStep = (): UseCreateYourMarketingPlanStepReturnValue => {
  const {
    projectId,
    filestackApiKey,
    project,
    participantId,
    mainGrowthStrategist,
    templateLink,
    uploadMarketingPlanDialogIsOpen = false,
    marketingPlanFile,
    marketingPlanStatus,
    eventHandlers,
  } = useMarketingPlanState();

  const dispatch = useMarketingPlanDispatch();
  const { onSuccessfulDataRefresh } = useStepperLogic();
  const { t } = useTranslation(TRANSLATION_NS, { keyPrefix: 'MarketingPlanProgress.MarketingPlanStepper' });

  const { onError, onUploadMarketingPlan, onDeleteMarketingPlan } = eventHandlers || ({} as MarketingPlanEventHandlers);

  const [isLoading, setIsLoading] = useState(false);

  const marketingPlanFileId = marketingPlanFile?.[0]?.id || undefined;
  const hasMarketingPlan = marketingPlanFileId !== undefined;

  const {
    name: mainGrowthStrategistName = t(
      'CreateYourMarketingPlanStep.maypleGrowthStrategist',
      'Mayple Growth Strategist',
    ) as string,
  } = mainGrowthStrategist || {};

  const {
    open: briefReportDialogIsOpen,
    openDialog: openBriefReportDialog,
    closeDialog: closeBriefReportDialog,
  } = useDialog();

  const {
    loading: createParticipantSharedFileLoading,
    mutate: createParticipantSharedFileMutation,
    error: createParticipantSharedFileError,
  } = useMutation(CreateParticipantSharedFileMutation);

  const {
    loading: replaceParticipantSharedFileLoading,
    mutate: replaceParticipantSharedFileMutation,
    error: replaceParticipantSharedFileError,
  } = useMutation(ReplaceParticipantSharedFileMutation);

  const {
    loading: removeParticipantSharedFileLoading,
    mutate: removeParticipantSharedFileMutation,
    error: removeParticipantSharedFileError,
  } = useMutation(RemoveParticipantSharedFileMutation);

  const openUploadMarketingPlanDialog = useCallback(() => {
    dispatch({ type: MarketingPlanActionType.OPEN_UPLOAD_DIALOG });
  }, [dispatch]);

  const closeUploadMarketingPlanDialog = useCallback(() => {
    dispatch({ type: MarketingPlanActionType.CLOSE_UPLOAD_DIALOG });
  }, [dispatch]);

  const openBriefReportDialogHandler = useCallback(() => {
    openBriefReportDialog();
    reportEvent('Link', 'Clicked', LINK_CLICK_LABEL_THE_BRIEF, { projectId });
  }, [openBriefReportDialog, projectId]);

  const copyTemplateLinkHandler = useCallback(() => {
    reportEvent('Link', 'Clicked', LINK_CLICK_LABEL_THIS_TEMPLATE, { projectId });
  }, [projectId]);

  const onUploadHandler: OnMarketingPlanUpload = useCallback(
    async (file) => {
      try {
        if (hasMarketingPlan) {
          const variables = {
            participantId,
            sharedFileId: marketingPlanFileId,
            sharedFileCreate: file,
          };

          await replaceParticipantSharedFileMutation({ variables });
        } else {
          const variables = {
            participantId,
            sharedFileCreate: file,
          };

          await createParticipantSharedFileMutation({ variables });
        }

        if (onUploadMarketingPlan) {
          onUploadMarketingPlan().then(onSuccessfulDataRefresh);
        }
      } catch (e) {
        if (onError && typeof onError === 'function') {
          onError(t('ErrorMessages.errorMessageUploadingMarketingPlan'));
        }
      }

      closeUploadMarketingPlanDialog();
    },
    [
      closeUploadMarketingPlanDialog,
      hasMarketingPlan,
      onUploadMarketingPlan,
      participantId,
      marketingPlanFileId,
      replaceParticipantSharedFileMutation,
      createParticipantSharedFileMutation,
      onSuccessfulDataRefresh,
      onError,
      t,
    ],
  );

  const onDeleteFileHandler: OnMarketingPlanDelete = useCallback(
    async (file: FileListFile | null) => {
      if (!file) {
        return;
      }

      try {
        const variables = {
          participantId: file.participantId,
          sharedFileId: file.id,
        };

        const result = await removeParticipantSharedFileMutation({ variables });
        if (result?.data?.removeParticipantSharedFile?.success === false) {
          throw new Error();
        }

        if (onDeleteMarketingPlan) {
          onDeleteMarketingPlan().then(onSuccessfulDataRefresh);
        }
      } catch (e) {
        if (onError && typeof onError === 'function') {
          onError(t('ErrorMessages.errorMessageDeletingMarketingPlan'));
        }
      }
    },
    [removeParticipantSharedFileMutation, onDeleteMarketingPlan, onSuccessfulDataRefresh, onError, t],
  );

  useEffect(() => {
    setIsLoading(
      createParticipantSharedFileLoading || replaceParticipantSharedFileLoading || removeParticipantSharedFileLoading,
    );
  }, [createParticipantSharedFileLoading, replaceParticipantSharedFileLoading, removeParticipantSharedFileLoading]);

  useEffect(() => {
    const hasError = createParticipantSharedFileError || replaceParticipantSharedFileError;

    if (onError && typeof onError === 'function' && hasError) {
      onError(t('ErrorMessages.errorMessageUploadingMarketingPlan'));
    }
  }, [createParticipantSharedFileError, replaceParticipantSharedFileError, onError, t]);

  useEffect(() => {
    const hasError = removeParticipantSharedFileError != null;
    if (onError && typeof onError === 'function' && hasError) {
      onError(t('ErrorMessages.errorMessageUploadingMarketingPlan'));
    }
  }, [removeParticipantSharedFileError, onError, t]);

  const filesListActions = useMemo(() => {
    const planApproved = checkMarketingPlanIsApprovedByCompany(marketingPlanStatus);

    if (planApproved) {
      return [];
    }

    return [
      {
        id: 'replace',
        label: 'Replace',
        onClick: openUploadMarketingPlanDialog,
      },
      {
        id: 'delete',
        label: 'Delete',
        onClick: onDeleteFileHandler,
      },
    ];
  }, [marketingPlanStatus, onDeleteFileHandler, openUploadMarketingPlanDialog]);

  return {
    isLoading,
    filestackApiKey,
    project,
    templateLink,
    uploadMarketingPlanDialogIsOpen,
    openUploadMarketingPlanDialog,
    marketingPlanFile,
    mainGrowthStrategistName,
    openBriefReportDialog: openBriefReportDialogHandler,
    hasMarketingPlan,
    briefReportDialogIsOpen,
    closeBriefReportDialog,
    closeUploadMarketingPlanDialog,
    onUploadHandler,
    filesListActions,
    copyTemplateLinkHandler,
  };
};

export default useCreateYourMarketingPlanStep;
