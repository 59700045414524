import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../../app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    error: {
      color: colors.error_text,
    },
    viewModeText: {},
  }),
);

export default useStyles;
