import React, { useMemo } from 'react';
import { useContactUsStyles } from '../../style';
import { ContactUsItem } from '../types';
import SimpleIcon from '../../../../atoms/SimpleIcon';
import { svgs } from '../../../../../app/svg';

const usePackageContactUsButtonProps = (): ContactUsItem[] => {
  const classes = useContactUsStyles();

  return useMemo(
    () => [
      // Uncomment this when chat is re-enabled on the backend
      //
      // {
      //   label:     'Chat with us now',
      //   onClick:   (e: MouseEvent) => {
      //     e.preventDefault();
      //     chatApi.openChat();
      //   },
      //   startIcon: (
      //     <SimpleIcon className={classes.icon} icon={svgs.MessagesBubbleForward} />
      //   ),
      // },
      {
        label: 'Send us an email',
        target: '_blank',
        href: 'mailto:sales@mayple.com',
        startIcon: <SimpleIcon className={classes.icon} icon={svgs.ReadEmailAt} />,
      },
      {
        label: '(929) 203-6341',
        href: 'tel:+19292036341',
        startIcon: <SimpleIcon className={classes.icon} icon={svgs.PhoneCircle} />,
      },
    ],
    [classes.icon],
  );
};

export default usePackageContactUsButtonProps;
