import React, { FC, useCallback } from 'react';
import { Participant, Project, ProjectSpecialSubType, ProjectSpecialType } from '@mayple/types';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';

import ProjectLaunch from './components/ProjectLaunch';
import HubspotDealLink from './components/HubspotDealLink';
import AssignedMarketer from './components/AssignedMarketer';
import EditProjectNameHeader from './components/EditProjectNameHeader';
import OwningCompany from './components/OwningCompany';
import ProjectHeaderCopyToSlack from './components/ProjectHeaderCopyToSlack';
import ProjectId from './components/ProjectId';
import ProjectStage from './components/ProjectStage';
import ProjectDetailsForSlack from './components/ProjectDetailsForSlack';
import ProjectCreationReason from './components/ProjectCreationReason';

import ProjectSupervisorChecker from '../ProjectSupervisorChecker';
import { useProjectDetailsState } from '../../ProjectDetailsProvider';
import { getMatchingSimulationPagePath } from '../../../consts';

import { config } from '../../../../config';
import EntityType from '../../../../app/types';
import NotesButton from '../../../../components/cpanel/Notes/NotesButton';
import {
  PROJECT_SPECIAL_SUBTYPE_LABELS_LOOKUP,
  PROJECT_SPECIAL_TYPE_LABELS_LOOKUP,
} from '../../../../../fe_common/client/app/enums';
import { Lookup } from '../../../../../fe_common/client/app/types';
import { Button } from '../../../../../fe_common/client/components/inputs';
import { TextDisplay } from '../../../../../fe_common/client/components/display';

import useStyles from './style';

interface ProjectDetailsHeaderProps {
  classes?: Record<string, string>;
}

const ProjectDetailsHeader: FC<ProjectDetailsHeaderProps> = (props) => {
  const classes = useStyles(props);
  const { project, refetchData } = useProjectDetailsState();
  const { supervisor, supervisorType, allowedSupervisorTypes, loadingSupervisor } = useProjectDetailsState();

  const {
    id: projectId,
    company,
    companyId,
    projectLifeCycleStatus,
    participants,
    hubspotDealId,
    // isUpSale,
    specialType,
  } = project || ({} as Project);

  const history = useHistory();

  const [participant] = participants || ([] as Participant[]);
  const assignedMarketer = participant && participant?.marketerId !== config.maamId ? participant : null;
  // const { hasAssignedGS, hasAssignedMC } = useHasAssignedInternalTeams(project);
  const projectSpecialTypeLabelsLookup = PROJECT_SPECIAL_TYPE_LABELS_LOOKUP as Lookup<ProjectSpecialType>;
  const projectSpecialSubTypeLabelsLookup = PROJECT_SPECIAL_SUBTYPE_LABELS_LOOKUP as Lookup<ProjectSpecialSubType>;
  // @ts-ignore
  const projectSpecialTypeLabel = projectSpecialTypeLabelsLookup[project.specialType];
  // @ts-ignore
  const projectSpecialSubTypeLabel = projectSpecialSubTypeLabelsLookup[project.specialSubType];

  const openMatchingSimulationPageHandler = useCallback(() => {
    if (!projectId) {
      return;
    }

    history.push(getMatchingSimulationPagePath(projectId));
  }, [projectId, history]);

  return (
    <div className={classes.root}>
      <div className={classes.headerProjectDetails}>
        <EditProjectNameHeader {...{ project }} classes={{ viewModeText: classes.viewModeText }} />
        <ProjectId projectId={projectId} projectLifeCycleStatus={projectLifeCycleStatus} />
        <OwningCompany {...{ company, companyId, projectId }} />
        {assignedMarketer && (
          <Grid item xs={12}>
            <AssignedMarketer {...{ assignedMarketer }} />
          </Grid>
        )}
        <HubspotDealLink hubspotDealId={hubspotDealId} classes={{ inlineItem: classes.inlineItem }} />
        {!!specialType && (
          <div className={classes.inlineItem}>
            <TextDisplay variant="subtitle1" inline>
              Project Type:{' '}
            </TextDisplay>
            <TextDisplay variant="subtitle1" inline className={classes.inlineItem}>
              {`${projectSpecialTypeLabel} - ${projectSpecialSubTypeLabel}`}
            </TextDisplay>
          </div>
        )}
        <ProjectStage project={project} onSuccess={refetchData} />
        <ProjectCreationReason project={project} />
      </div>
      <div className={classes.headerProjectUtilities}>
        <div className={classes.headerProjectActions}>
          <div>
            <ProjectHeaderCopyToSlack projectId={projectId} />
            <ProjectDetailsForSlack project={project} />
          </div>
          <NotesButton entityId={projectId} entityType={EntityType.Project} />

          <Button
            label="Open matching simulator"
            onClick={openMatchingSimulationPageHandler}
            color="primary"
            variant="outlined"
            size="small"
          />
        </div>
        <div className={classes.projectLaunchDate}>
          <ProjectLaunch project={project} onUpdateSuccess={refetchData} />
        </div>
        {!loadingSupervisor && (
          <ProjectSupervisorChecker
            projectId={projectId}
            supervisor={supervisor}
            supervisorType={supervisorType}
            allowedSupervisorTypes={allowedSupervisorTypes}
          />
        )}
        {/*
        // replaced by the above component ProjectSupervisorChecker - left here for some reference
        {(!hasAssignedGS ||
          (!hasAssignedMC &&
            !isUpSale &&
            ![ProjectSpecialType.MAILCHIMP_AUDIT_TYPE, ProjectSpecialType.MAILCHIMP_AUDIT_TYPE].includes(
              specialType,
            ))) && <CalloutMessage message="Please assign a main GS \ MC" type="warning" margin />}
        */}
      </div>
    </div>
  );
};

export default ProjectDetailsHeader;
