import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/AddCircle';
import HotIcon from '@material-ui/icons/Whatshot';

const useStyles = makeStyles(() =>
  createStyles({
    chip: { marginTop: 16, marginRight: 8 },
    icon: { color: '#FF6A00' },
  }),
);

const getFinalOptions = (options, optionsToFilter = []) => {
  if (!options || options.length === 0) {
    return [];
  }

  return options.filter(
    ({ value }) => !(optionsToFilter || []).map(({ value: valueToFilter }) => valueToFilter).includes(value),
  );
};

function HotEnums({ options, optionsToFilter, defaultIcon, onClick }) {
  const classes = useStyles();
  const defaultIconFinal = defaultIcon || <HotIcon className={classes.icon} />;

  const [finalOptions, setFinalOptions] = useState(getFinalOptions(options, optionsToFilter));

  useEffect(() => {
    setFinalOptions(getFinalOptions(options, optionsToFilter));
  }, [options, optionsToFilter]);

  if (!finalOptions || finalOptions.length === 0) {
    return null;
  }

  return (
    <div>
      {finalOptions.map((item) => {
        const { value, label, chipLabel, icon } = item;
        const iconToRender = typeof icon === 'string' && icon !== '' ? <img src={icon} alt={label} /> : icon;

        return (
          <Chip
            key={`chip-hot-item-key-${value}-auto-complete`}
            variant="outlined"
            icon={iconToRender || defaultIconFinal}
            label={chipLabel || label}
            onClick={() => {
              onClick?.(item);
            }}
            onDelete={() => {
              onClick?.(item);
            }}
            deleteIcon={<AddIcon />}
            className={classes.chip}
          />
        );
      })}
    </div>
  );
}

HotEnums.propTypes = {
  options: PropTypes.array,
  optionsToFilter: PropTypes.array,
  onClick: PropTypes.func,
  defaultIcon: PropTypes.node,
};
HotEnums.defaultProps = {
  defaultIcon: null,
  options: [],
  optionsToFilter: [],
  onClick: undefined,
};

export default HotEnums;
