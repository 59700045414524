import { AssessmentResponse } from '@mayple/types';

import { TaskListItemData } from './types';

import { SurveyUniqueCode } from '../../../hooks/assessment';

const getTaskListItemData = (assessment: AssessmentResponse): TaskListItemData => {
  if (assessment?.surveyUniqueCode === SurveyUniqueCode.postProjectMatchOfferMarketerAssessment_v1) {
    const primaryText = `Expert (${assessment?.marketerId}) Monthly Marketer Assessment`;
    const secondaryText = `Company: (${assessment.companyId})\n Project: (${assessment.projectId})`;

    return {
      primaryText,
      secondaryText,
    };
  }

  return {
    primaryText: `Missing implementation for ${assessment.surveyUniqueCode}`,
  };
};

export default getTaskListItemData;
