import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { colors } from '../../../../app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      flexGrow: 1,
      height: '100%',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
    },
    nonPpcCard: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      flexGrow: 1,
      backgroundClip: 'padding-box',
      boxShadow: `0px 6px 12px ${colors.blackOpacity(0.16)}, 0px 7px 32px ${colors.blackOpacity(0.24)}`,
      transition: 'ease-in 300ms',
      padding: '0.5rem 1rem',
      // '&:hover': {
      //   boxShadow: `0 10px 18px ${colors.blackOpacity(0.2)}, 0 14px 45px ${colors.blackOpacity(0.32)}`,
      //   transition: 'ease-in 300ms',
      // },
    },
    richTextContainerLimit: {
      maxHeight: '5rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    selected: {
      border: `${colors.primary} 2px inset`,
    },
    selectedServiceBtn: {},
    selectedServiceBtnDisabled: {},
    learnMoreContainer: {
      marginTop: '2rem',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    learnMore: {
      textTransform: 'none',
      fontWeight: 'normal',
      color: colors.primary,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
        backgroundColor: 'transparent',
      },
    },
  }),
);
export default useStyles;
