import React from 'react';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { TextDisplay } from '../../index';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '2rem 1rem',
      gap: '2rem',
      display: 'flex',
      flexDirection: 'column',
    },
    filterBody: {
      display: 'flex',
      flexDirection: 'column',
      '& > *:last-child': {
        borderBottom: `0 none`,
        paddingBottom: 0,
      },
    },
  }),
);

interface FilterPanelProps {
  title?: string;
  className?: string;
  classes?: Record<string, string>;
}

const FilterPanel: React.FC<FilterPanelProps> = (props) => {
  const classes = useStyles(props);
  const { title = '', className, children } = props;

  return (
    <Paper elevation={0} className={classNames(className, classes.root)}>
      {title && <TextDisplay variant="overline">{title}</TextDisplay>}
      <div className={classes.filterBody}>{children}</div>
    </Paper>
  );
};

export default FilterPanel;
