import React from 'react';

import { DialogProps } from '../../display/Dialog/types';
import { Dialog } from '../../display';
import { AssessmentFilter } from '../../../hooks/assessment';

interface TaskDialogProps extends DialogProps {
  assessmentFilter?: AssessmentFilter | null | undefined;
}

const TaskDialog: React.FC<TaskDialogProps> = (props) => {
  const { assessmentFilter, ...rest } = props;

  return <Dialog {...rest} />;
};

export default TaskDialog;
