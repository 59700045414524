import { FilterByOperator, FilterRequest } from '@mayple/types';

import { AssessmentFilter } from './types';

const useAssessmentFilter = (assessmentFilter?: AssessmentFilter): FilterRequest => {
  const { state, projectId, assessmentType } = assessmentFilter || ({} as AssessmentFilter);

  const filter: FilterRequest = {
    by: [],
  };

  if (state) {
    filter.by?.push({ key: 'state', value: state.toString(), operator: FilterByOperator.IN });
  }

  if (projectId) {
    filter.by?.push({ key: 'projectId', value: projectId.toString(), operator: FilterByOperator.EQUALS });
  }

  if (assessmentType) {
    filter.by?.push({ key: 'surveyUniqueCode', value: assessmentType, operator: FilterByOperator.EQUALS });
  }

  return filter;
};

export default useAssessmentFilter;
