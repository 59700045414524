import React from 'react';
import PropTypes from 'prop-types';

import EnumAnswer from './Renderers/EnumAnswer';
import StringAnswer from './Renderers/StringAnswer';
import CurrencyRangeAnswer from './Renderers/CurrencyRangeAnswer';
import NumericRangeAnswer from './Renderers/NumericRangeAnswer';
import NumberAnswer from './Renderers/NumberAnswer';
import CurrencyAnswer from './Renderers/CurrencyAnswer';
import UrlAnswer from './Renderers/UrlAnswer';

import { TextDisplay } from '../../display';
import { clientLogger } from '../../../services/logger';
import DateAnswer from './Renderers/DateAnswer';

import { ANSWER_TYPES } from '../../../app/consts';

/**
 *
 */
class BriefAnswerRenderer extends React.Component {
  constructor(props) {
    super(props);

    const { question, answer } = props;

    // console.log('BriefAnswerRenderer question', question);
    // console.log('BriefAnswerRenderer answer', answer);

    if (answer.questionUuid !== question.uuid) {
      throw new Error(
        `answer.questionUuid(${answer.questionUuid}) does not correlate with question.uuid(${question.uuid})`,
      );
    }

    this.answerTypeRendererMapper = {
      [ANSWER_TYPES.STRING]: StringAnswer,
      [ANSWER_TYPES.ENUM]: EnumAnswer,
      [ANSWER_TYPES.NUMBER]: NumberAnswer,
      [ANSWER_TYPES.NUMERIC_RANGE]: NumericRangeAnswer,
      [ANSWER_TYPES.CURRENCY]: CurrencyAnswer,
      [ANSWER_TYPES.CURRENCY_RANGE]: CurrencyRangeAnswer,
      [ANSWER_TYPES.URL]: UrlAnswer,
      [ANSWER_TYPES.DATE]: DateAnswer,
    };

    this.acceptableAnswerTypes = Object.keys(this.answerTypeRendererMapper);
  }

  answerTypeRenderer = (answerType) => this.answerTypeRendererMapper[answerType];

  renderAnswer = (question, answer) => {
    const { answerType } = question;

    if (!this.acceptableAnswerTypes.includes(answerType)) {
      clientLogger.error(`answerType: ${answerType} is missing a Renderer component`);
      return null;
    }

    const Renderer = this.answerTypeRenderer(answerType);
    return <Renderer answer={answer} question={question} />;
  };

  render() {
    const { question, answer } = this.props;

    return (
      <div>
        <TextDisplay variant="h6" bold gutterBottom>
          {question.headline}
        </TextDisplay>
        {this.renderAnswer(question, answer)}
      </div>
    );
  }
}

BriefAnswerRenderer.propTypes = {
  question: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
};

export default BriefAnswerRenderer;
