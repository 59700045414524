import { Participant } from '@mayple/types';
import { useQuery } from '@apollo/react-hooks';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { MarketerParticipantQuery } from 'growl-graphql/dist/queries/MarketerParticipantQuery';

type MarketerParticipantQueryResult = {
  participant: Participant;
};

export type MarketerParticipantData = {
  participant: Participant;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (
    variables?: { projectId: number | null | undefined; marketerId: number | null | undefined } | undefined,
  ) => Promise<ApolloQueryResult<MarketerParticipantQueryResult>>;
};

const useMarketerParticipantData = (
  projectId: number | null | undefined,
  marketerId: number | null | undefined,
): MarketerParticipantData => {
  const { data, loading, error, refetch } = useQuery(MarketerParticipantQuery.query, {
    variables: { projectId, marketerId },
    skip: !projectId || !marketerId,
    notifyOnNetworkStatusChange: true,
  });

  return {
    participant: data?.participant || ({} as Participant),
    loading,
    error,
    refetch,
  };
};

export default useMarketerParticipantData;
