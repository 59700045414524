import React, { ReactNode } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import TextDisplay from '../../display/TextDisplay';
import { useDeviceInfo } from '../../../hooks';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

interface SectionTitleProps {
  title: ReactNode;
  classes?: Record<string, string>;
}

const SectionTitle: React.FC<SectionTitleProps> = (props) => {
  const classes = useStyles(props);
  const { title } = props;
  const { isMobile } = useDeviceInfo();

  return (
    <header>
      <TextDisplay variant={isMobile ? 'h6' : 'h5'} className={classes.root}>
        {title}
      </TextDisplay>
    </header>
  );
};

export default SectionTitle;
