import React, { useCallback } from 'react';
import { SharedFileCreate } from '@mayple/types';

import { FileUploadButtonProps } from './types';
import Button from '../Button';
import FileUploadDialog from '../FileUploadDialog';

import { useDialog } from '../../../hooks';

import useStyles from './style';

const FileUploadButton: React.FC<FileUploadButtonProps> = (props) => {
  const classes = useStyles(props);

  const {
    label,
    disabled,
    loading = false,
    color = 'primary',
    variant = 'contained',
    title = 'Upload File',
    filestackApiKey,
    filestackOptions = {},
    filename,
    defaultTab,
    onFileUpload,
    callout,
    category,
    tabs,
  } = props;

  const {
    open: fileUploadDialogOpen,
    openDialog: openFileUploadDialog,
    closeDialog: closeFileUploadDialog,
  } = useDialog(false);

  const onFileUploadHandler = useCallback(
    async (f: SharedFileCreate) => {
      await onFileUpload?.(f);
      closeFileUploadDialog();
    },
    [onFileUpload, closeFileUploadDialog],
  );

  return (
    <div className={classes.root}>
      <Button
        label={label}
        onClick={openFileUploadDialog}
        disabled={disabled}
        loading={loading}
        color={color}
        variant={variant}
        className={classes.uploadButton}
      />

      <FileUploadDialog
        filestackApiKey={filestackApiKey}
        isOpen={fileUploadDialogOpen}
        onClose={closeFileUploadDialog}
        title={title}
        tabs={tabs}
        onFileUpload={onFileUploadHandler}
        category={category}
        loading={loading}
        filestackOptions={filestackOptions}
        filename={filename}
        defaultTab={defaultTab}
        callout={callout}
      />
    </div>
  );
};

export default FileUploadButton;
