import { CronofyAvailabilityProfiles, CronofyMembersEventQuery } from '@mayple/types';
import keyBy from 'lodash/keyBy';

import { IAvailabilityViewerContext } from './AvailabilityViewerContext';
import { AvailabilityViewerFilterData } from '../types';
import { AccountWithCronofyIntegration, AccountWithIntegration } from '../../types';
import { Slot, AvailabilityViewerCallbackObject } from '../../types/cronofyTypes';

export enum AvailabilityViewerActionType {
  UPDATE_FILTER = 'UPDATE_FILTER',
  UPDATE_MEMBERS = 'UPDATE_MEMBERS',
  UPDATE_ACCOUNTS_INTEGRATIONS = 'UPDATE_ACCOUNTS_INTEGRATIONS',
  SLOT_SELECTED = 'SLOT_SELECTED',
}

interface ReducerAction {
  type: string;
  payload?: any;
}

export interface AvailabilityViewerAction extends ReducerAction {
  type: AvailabilityViewerActionType;
  payload:
    | AvailabilityViewerFilterData
    | CronofyMembersEventQuery[]
    | AccountWithIntegration[]
    | AvailabilityViewerCallbackObject
    | null;
}

export type AvailabilityViewerDispatch = (action: ReducerAction) => void;

const getMembersById = (payload: CronofyMembersEventQuery[]): Record<string, CronofyMembersEventQuery> =>
  keyBy(payload, 'accountUuid');

const getSlotData = (payload: AvailabilityViewerCallbackObject): Slot | null => {
  if (payload) {
    return {
      ...payload?.notification?.slot,
    };
  }
  return null;
};

export const applyMembersToAccountWithCronofyIntegration = (
  accounts: AccountWithCronofyIntegration[] | null | undefined,
  members: CronofyMembersEventQuery[] | null | undefined,
): AccountWithCronofyIntegration[] =>
  (accounts || []).map((account: AccountWithCronofyIntegration) => {
    const member: CronofyMembersEventQuery | undefined = (members || []).find(
      ({ accountUuid }) => account?.externalEntityId === accountUuid,
    );

    if (member != null) {
      return {
        ...account,
        sub: member.sub as string | undefined,
        availabilityProfiles: member.availabilityProfiles as Array<CronofyAvailabilityProfiles> | null | undefined,
        managedAvailability: member.managedAvailability as boolean | undefined,
        calendarIds: member.calendarIds as string[] | null | undefined,
      };
    }

    return account;
  });

export const availabilityViewerReducer = (
  availabilityViewerContextState: IAvailabilityViewerContext,
  action: ReducerAction,
): IAvailabilityViewerContext => {
  const { type, payload = null } = action;

  switch (type) {
    case AvailabilityViewerActionType.UPDATE_FILTER:
      return {
        ...availabilityViewerContextState,
        filterData: {
          ...availabilityViewerContextState.filterData,
          ...payload,
        },
      };
    case AvailabilityViewerActionType.UPDATE_MEMBERS:
      return {
        ...availabilityViewerContextState,
        accountsWithIntegration: applyMembersToAccountWithCronofyIntegration(
          availabilityViewerContextState?.accountsWithIntegration,
          payload,
        ),
        membersById: {
          ...getMembersById(payload),
        },
      };
    case AvailabilityViewerActionType.UPDATE_ACCOUNTS_INTEGRATIONS: {
      return {
        ...availabilityViewerContextState,
        accountsWithIntegration: [...payload],
      };
    }
    case AvailabilityViewerActionType.SLOT_SELECTED: {
      return {
        ...availabilityViewerContextState,
        selectedSlot: getSlotData(payload),
      };
    }
    default:
      return availabilityViewerContextState;
  }
};
