import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';

import { ProjectStopReasonsFormValues, StopReasonsFormProps } from '../types';
import HelpTooltip from '../HelpTooltip';

import { TextDisplay } from '../../../display';
import { Button, RadioGroup } from '../../../inputs';
import { parseFormValuesToBackendFormat } from '../utils';
import { getDefaultFormValues } from '../helpers/getDefaultFormValues';
import { getQuestionList } from '../helpers/getQuestionList';

import useStyles from './style';

const StopReasonsForm: React.FC<StopReasonsFormProps> = (props) => {
  const classes = useStyles(props);

  const { project, handleStopProject, isComponentCustomerFacing, isStopProjectImmediately, handleCancel } = props;

  const { setValue, watch, handleSubmit, register, formState } = useForm<ProjectStopReasonsFormValues>({
    defaultValues: getDefaultFormValues(),
  });

  const projectId = project?.id;
  const marketer = project?.participants?.[0];
  const marketerName = marketer?.marketerMatch?.name;

  const questions = getQuestionList(isComponentCustomerFacing, marketerName || 'the expert');

  const { isSubmitting } = formState;

  const onSubmit = useCallback(
    (formValues: ProjectStopReasonsFormValues) => {
      if (isSubmitting) {
        return;
      }

      const parsedForm = parseFormValuesToBackendFormat(formValues, projectId, isStopProjectImmediately);
      handleStopProject(parsedForm);
    },
    [handleStopProject, isStopProjectImmediately, isSubmitting, projectId],
  );

  const onCancel = useCallback(() => {
    handleCancel?.();
  }, [handleCancel]);

  return (
    // @ts-ignore
    <form onSubmit={handleSubmit(onSubmit)}>
      {questions.map(({ name, options, title, tooltip }) => {
        const onChange = (e: React.BaseSyntheticEvent) => {
          setValue(name as keyof ProjectStopReasonsFormValues, e.target.value);
        };

        return (
          <div key={name} className={classes.questionContainer}>
            <div className={classes.questionTitleFlexContainer}>
              <TextDisplay className={classes.questionTitle}>{title}</TextDisplay>
              {!!tooltip && <HelpTooltip text={tooltip} />}
            </div>

            <RadioGroup
              name={name}
              options={options}
              onChange={onChange}
              value={watch(name as keyof ProjectStopReasonsFormValues) as unknown as string}
            />
          </div>
        );
      })}

      <TextField
        {...register('description')}
        error={!!formState?.errors?.description || !!''}
        multiline
        label="We also care about your opinion - feel free to share it here"
        variant="outlined"
        className={classes.descriptionTextArea}
      />

      <div className={classes.buttonContainer}>
        <Button onClick={onCancel} label="CANCEL" />
        <Button type="submit" label="STOP" color="primary" variant="contained" disabled={isSubmitting} />
      </div>
    </form>
  );
};

export default StopReasonsForm;
