import { useCallback, useMemo } from 'react';
import { DeliverableBlueprint, ProjectLifeCycleStatus, ProjectPackageState } from '@mayple/types';

import { ReportProjectPackageAsCanceledMutation } from 'growl-graphql/dist/mutations/project/projectPackage/ReportProjectPackageAsCanceledMutation';
import { ReportProjectPackageAsStartedMutation } from 'growl-graphql/dist/mutations/project/projectPackage/ReportProjectPackageAsStartedMutation';
import { DisableProjectPackageMutation } from 'growl-graphql/dist/mutations/project/projectPackage/DisableProjectPackageMutation';
import { ActivateProjectPackageMutation } from 'growl-graphql/dist/mutations/project/projectPackage/ActivateProjectPackageMutation';

import { ProjectPackageAction, ProjectPackageCardData, ProjectPackageCardProps } from './types';
import { useDialog, useMutation } from '../../../hooks';
import mergeByProperty from '../../../logic/mergeByProperty';
import { handleClientError } from '../../../services/logger';
import { MenuItemWithLabelProps } from '../../inputs/MultipleActionsButton/types';

const useProjectPackageCardData = (props: ProjectPackageCardProps): ProjectPackageCardData => {
  const { projectPackage, projectLifeCycleStatus, onSuccessfulSave } = props;

  const { uuid: projectPackageUuid } = projectPackage;
  const projectPackageState = projectPackage?.state;

  const {
    open: isMarkAsCompletedDialogOpen,
    openDialog: openMarkAsCompletedDialog,
    closeDialog: closeMarkAsCompletedDialog,
  } = useDialog(false);

  const {
    open: isReportPackageDelayDialogOpen,
    openDialog: openReportPackageDelayDialog,
    closeDialog: closeReportPackageDelayDialog,
  } = useDialog(false);

  const { mutate: reportProjectPackageAsCanceled, loading: savingReportAsCanceled } = useMutation(
    ReportProjectPackageAsCanceledMutation,
  );

  const { mutate: reportProjectPackageAsStarted, loading: savingReportAsStarted } = useMutation(
    ReportProjectPackageAsStartedMutation,
  );

  const { mutate: disableProjectPackage, loading: savingDisablePackage } = useMutation(DisableProjectPackageMutation);

  const { mutate: activateProjectPackage, loading: savingActivatePackage } =
    useMutation(ActivateProjectPackageMutation);

  const finalDeliverablesBlueprints: DeliverableBlueprint[] = useMemo(
    () =>
      mergeByProperty<DeliverableBlueprint>(
        projectPackage?.deliverablesBlueprintsOverride || [],
        projectPackage?.package?.deliverablesBlueprints || [],
        'serviceType',
      ),
    [projectPackage?.deliverablesBlueprintsOverride, projectPackage?.package?.deliverablesBlueprints],
  );

  const [finalDeliverablesBlueprint] = finalDeliverablesBlueprints;

  const reportProjectPackageAsCanceledHandler = useCallback(async (): Promise<void> => {
    try {
      const variables = {
        projectPackageUuid,
      };

      await reportProjectPackageAsCanceled({ variables });
      await onSuccessfulSave?.();
    } catch (e) {
      handleClientError(e, `Error while trying to cancel a package ${projectPackageUuid}.`);
    }
  }, [onSuccessfulSave, projectPackageUuid, reportProjectPackageAsCanceled]);

  const reportProjectPackageAsStartedHandler = useCallback(async (): Promise<void> => {
    try {
      const variables = {
        projectPackageUuid,
      };

      await reportProjectPackageAsStarted({ variables });
      await onSuccessfulSave?.();
    } catch (e) {
      handleClientError(e, `Error while trying to start a package ${projectPackageUuid}.`);
    }
  }, [onSuccessfulSave, projectPackageUuid, reportProjectPackageAsStarted]);

  const onMarkAsStartedClickHandler = useCallback(async () => {
    await reportProjectPackageAsStartedHandler();
  }, [reportProjectPackageAsStartedHandler]);

  const onMarkAsCanceledClickHandler = useCallback(async () => {
    await reportProjectPackageAsCanceledHandler();
  }, [reportProjectPackageAsCanceledHandler]);

  const onReportDelayClickHandler = useCallback(async () => {
    openReportPackageDelayDialog();
  }, [openReportPackageDelayDialog]);

  const onMarkAsCompletedClickHandler = useCallback(async () => {
    openMarkAsCompletedDialog();
  }, [openMarkAsCompletedDialog]);

  const disableProjectPackageHandler = useCallback(async (): Promise<void> => {
    try {
      const variables = {
        projectPackageUuid,
      };

      await disableProjectPackage({ variables });
      await onSuccessfulSave?.();
    } catch (e) {
      handleClientError(e, 'Error while trying to update package delivery.');
    }
  }, [disableProjectPackage, onSuccessfulSave, projectPackageUuid]);

  const onDisableProjectPackageClickHandler = useCallback(async () => {
    await disableProjectPackageHandler();
  }, [disableProjectPackageHandler]);

  const activateProjectPackageHandler = useCallback(async (): Promise<void> => {
    try {
      const variables = {
        projectPackageUuid,
      };

      await activateProjectPackage({ variables });
      await onSuccessfulSave?.();
    } catch (e) {
      handleClientError(e, 'Error while trying to activate package.');
    }
  }, [activateProjectPackage, onSuccessfulSave, projectPackageUuid]);

  const onActivateProjectPackageClickHandler = useCallback(async () => {
    await activateProjectPackageHandler();
  }, [activateProjectPackageHandler]);

  const actions: MenuItemWithLabelProps[] = useMemo(
    () => [
      {
        id: ProjectPackageAction.ProjectPackageActionDisable,
        label: 'Delete package',
        onClick: onDisableProjectPackageClickHandler,
        disabled: projectPackageState !== ProjectPackageState.PACKAGE_DRAFT || savingDisablePackage,
      },
      {
        id: `project-package-action-report-cancel`,
        label: 'Mark as canceled',
        onClick: onMarkAsCanceledClickHandler,
        disabled:
          projectLifeCycleStatus === ProjectLifeCycleStatus.DISCOVERY ||
          projectPackageState === ProjectPackageState.PACKAGE_CANCELED ||
          projectPackageState === ProjectPackageState.PACKAGE_DRAFT ||
          savingReportAsCanceled,
      },
      {
        id: `project-package-action-activate`,
        label: 'Activate package',
        onClick: onActivateProjectPackageClickHandler,
        disabled: projectPackageState !== ProjectPackageState.PACKAGE_DRAFT || savingActivatePackage,
      },
      {
        id: `project-package-action-report-started`,
        label: 'Mark as started',
        onClick: onMarkAsStartedClickHandler,
        disabled:
          projectLifeCycleStatus === ProjectLifeCycleStatus.DISCOVERY ||
          (projectPackageState !== ProjectPackageState.PACKAGE_PENDING &&
            projectPackageState !== ProjectPackageState.PACKAGE_CANCELED) ||
          savingReportAsStarted,
      },
      {
        id: `project-package-action-report-delay`,
        label: 'Mark as delayed',
        onClick: onReportDelayClickHandler,
        disabled:
          projectLifeCycleStatus !== ProjectLifeCycleStatus.LIVE ||
          projectPackageState !== ProjectPackageState.PACKAGE_STARTED,
      },
      {
        id: `project-package-action-report-completed`,
        label: 'Mark as completed',
        onClick: onMarkAsCompletedClickHandler,
        disabled:
          projectLifeCycleStatus === ProjectLifeCycleStatus.DISCOVERY ||
          projectPackageState === ProjectPackageState.PACKAGE_COMPLETED ||
          projectPackageState !== ProjectPackageState.PACKAGE_STARTED,
      },
    ],
    [
      onActivateProjectPackageClickHandler,
      onDisableProjectPackageClickHandler,
      onMarkAsCanceledClickHandler,
      onMarkAsCompletedClickHandler,
      onReportDelayClickHandler,
      onMarkAsStartedClickHandler,
      projectLifeCycleStatus,
      projectPackageState,
      savingActivatePackage,
      savingDisablePackage,
      savingReportAsCanceled,
      savingReportAsStarted,
    ],
  );

  return {
    actions,
    finalDeliverablesBlueprint,
    isMarkAsCompletedDialogOpen,
    closeMarkAsCompletedDialog,
    isReportPackageDelayDialogOpen,
    closeReportPackageDelayDialog,
  };
};
export default useProjectPackageCardData;
