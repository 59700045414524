import { ProjectStage } from '@mayple/types';

export const stageTitle: Record<ProjectStage, string> = {
  [ProjectStage.WAITING_FOR_PACKAGE]: 'Package Selection',
  [ProjectStage.PACKAGE_UNDER_CLIENT_REVIEW]: 'Package Review',
  [ProjectStage.DATA_ENRICHMENT]: 'Client-Profile Enrichment',
  [ProjectStage.OPPORTUNITIES]: 'Expert Matching',
  [ProjectStage.MARKETER_APPROVAL]: 'Expert Approval',
  [ProjectStage.EXISTING_MARKETING_EVALUATION]: 'Existing Marketing Evaluation (Audit)',
  [ProjectStage.MARKETING_PLAN]: 'Marketing Plan',
  [ProjectStage.LAUNCH]: 'Project Launch',
  [ProjectStage.SETUP]: 'Accounts Setup',
  [ProjectStage.EXISTING_MARKETING_OPTIMIZATION_IMPLEMENTATION]: 'Waiting for deliverables',
  [ProjectStage.MONITORING]: 'Live (Monitoring)',
  [ProjectStage.STOPPED]: 'Project stopped',
};

export const checkIfStagePassed = (
  currentStage: ProjectStage | undefined | null,
  targetStage: ProjectStage | undefined | null,
  inclusive = true,
): boolean => {
  if (!targetStage || !currentStage) {
    return false;
  }

  const stages = Object.values(ProjectStage);
  const indexOfCurrentStage = stages.indexOf(currentStage);
  const indexOfTargetStage = stages.indexOf(targetStage);

  if (!inclusive) {
    return indexOfCurrentStage > indexOfTargetStage;
  }

  return indexOfCurrentStage >= indexOfTargetStage;
};
