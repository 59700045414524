import { PpcBillingModelPlanName, PpcMeteredPlanMultiplier, PpcTieredPlan, PpcTieredPlanTier } from '@mayple/types';

import { safeExtractCurrencyValue } from '../services/utils';
import { getDiscountedCost } from '../services/utils/number';

type PpcTierLabels = { maxSpend: number; price: string; label: string };
type PpcMeteredMultiplierLabel = { maxSpend: number; multiplier: string; label: string };

export const getCurrentPpcTier = (
  tiers: { maxSpend: number; price: string; label: string }[],
  spend: number,
): Record<string, any> => {
  const tier = tiers.find(({ maxSpend }) => spend <= maxSpend);

  return {
    label: tier?.label || 'N/A',
    price: tier?.price || '0',
  };
};
//
const format = (cost: number, discount = 0) => safeExtractCurrencyValue(getDiscountedCost(cost, discount || 0));

export const createPpcTierLabels = (tiers: PpcTieredPlanTier[], discount = 0): PpcTierLabels[] => {
  try {
    return tiers.map((tier, index, arr) => {
      const { price, maxSpend } = tier;

      if (index === 0) {
        return {
          label: `up to ${format(tier.maxSpend)}`, // do no reduce amount for spend
          maxSpend,
          price: format(price, discount),
        };
      }

      if (index === arr.length - 1) {
        return {
          label: `${format(arr[index - 1].maxSpend)} or more`, // do no reduce amount for spend
          maxSpend,
          price: format(price, discount),
        };
      }

      return {
        label: `${format(arr[index - 1].maxSpend)} - ${format(tier.maxSpend)}`, // do no reduce amount for spend
        maxSpend,
        price: format(price, discount),
      };
    });
  } catch (e) {
    return [];
  }
};

export const createPpcMeteredMultipliersLabels = (
  multipliers: PpcMeteredPlanMultiplier[],
  discount = 0,
): PpcMeteredMultiplierLabel[] => {
  try {
    return multipliers.map((m, index, arr) => {
      const { multiplier, maxSpend } = m;

      if (index === 0) {
        return {
          label: `up to ${format(m.maxSpend)}`, // do no reduce amount for spend
          maxSpend,
          multiplier: format(multiplier, discount),
        };
      }

      if (index === arr.length - 1) {
        return {
          label: `${format(arr[index - 1].maxSpend)} or more`, // do no reduce amount for spend
          maxSpend,
          multiplier: format(multiplier, discount),
        };
      }

      return {
        label: `${format(arr[index - 1].maxSpend)} - ${format(m.maxSpend)}`, // do no reduce amount for spend
        maxSpend,
        multiplier: format(multiplier, discount),
      };
    });
  } catch (e) {
    return [];
  }
};

export type PpcTiersLabels = {
  labels: PpcTierLabels[];
  currentTierLabel: string;
  currentTierPrice: string;
};

export const getPpcTiersLabels = (
  tiers: PpcTieredPlan[],
  spend: number,
  currentPlanName: PpcBillingModelPlanName | undefined,
  discount = 0,
): PpcTiersLabels => {
  if (!tiers || !currentPlanName) {
    return { labels: [], currentTierLabel: '', currentTierPrice: '0' };
  }

  const currentPlanTiers = (tiers.find(({ planName }) => planName === currentPlanName) || {}).tiers || [];

  const labels = createPpcTierLabels(currentPlanTiers, discount);

  const { label: currentTierLabel, price: currentTierPrice } = getCurrentPpcTier(labels, spend);

  return {
    labels,
    currentTierLabel: currentTierLabel.replace(/(\.00)/gi, ''),
    currentTierPrice: currentTierPrice.replace(/(\.00)/gi, ''),
  };
};
