import React, { FC, useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';

import { PackageContext } from '../logic';
import PackageImage from './PackageImage';

const PackageExamplesCarousel: FC = () => {
  const { data } = useContext(PackageContext);
  const images = data?.marketingServiceTypeInfo?.example?.images || [];

  return (
    <div>
      <Carousel showThumbs={false} showStatus={false}>
        {images.map(
          // @ts-ignore
          (img, i: number) => (
            <div key={`PackageExamplesCarousel-PackageImage-${i}`}>
              <PackageImage image={img.image} component="div" />
            </div>
          ),
        )}
      </Carousel>
    </div>
  );
};

export default PackageExamplesCarousel;
