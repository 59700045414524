import { useQuery } from 'react-apollo';
import { DocumentNode } from 'graphql';
import { FilterRequest, PaginationRequest } from '@mayple/types';
import { QueryHookOptions } from '@apollo/react-hooks/lib/types';
import { ApolloError, ApolloQueryResult } from 'apollo-client';

const DEFAULT_PAGE_SIZE = 20;

const defaultPagingSorting: PagingSortingVariables = {
  pagination: {
    pageNumber: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  },
};

export interface PagingSortingVariables extends Record<string, any> {
  pagination?: PaginationRequest;
  filter?: FilterRequest;
}

export interface PagedQuery {
  loading: boolean;
  data: any;
  itemsTotal: number;
  numPages: number;
  error: ApolloError | undefined;
  refetch: (variables?: PagingSortingVariables | undefined) => Promise<ApolloQueryResult<any>>;
  variables: PagingSortingVariables;
}

const usePagedQuery = (
  query: DocumentNode,
  queryDataKey: string,
  variables: PagingSortingVariables = defaultPagingSorting,
  options: QueryHookOptions = {},
): PagedQuery => {
  const queryOptions = {
    ...options,
    variables,
  };

  const { data, loading, error, refetch, variables: usedVariables } = useQuery(query, queryOptions);

  const pageSize = variables?.pagination?.pageSize || DEFAULT_PAGE_SIZE;
  const numPages = Math.ceil((data?.[queryDataKey]?.itemsTotal ?? 0) / pageSize);

  return {
    loading,
    data: data?.[queryDataKey]?.items,
    itemsTotal: data?.[queryDataKey]?.itemsTotal,
    numPages,
    error,
    refetch,
    variables: usedVariables,
  };
};

export default usePagedQuery;
