import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    textField: {},
    results: {
      listItemStyle: 'none',
    },
    autoCompleteValueContainer: {
      padding: '0 8px',
    },
  }),
);

export default useStyles;
