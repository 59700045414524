import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    uploadMarketingPlanButton: {
      display: 'block',
      marginTop: 32,
      marginBottom: 32,
    },
    fileListRoot: {
      marginTop: 32,
      marginBottom: 40,
    },
  }),
);

export default useStyles;
