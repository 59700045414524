import React from 'react';
import PropTypes from 'prop-types';
import { Wizard } from 'react-albus/lib';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, getFormValues, getFormSyncErrors } from 'redux-form';

/**
 *
 * this WizardForm component requires a steps prop, which is simply a function that returns the steps built using
 * react-albus package
 *
 * for reference on how to use this WizardForm, look at /src/client/pages/CompanyCreationPage/index.js
 * and for how to create steps look at src/client/containers/company/wizards/CompanyCreationWizard/steps.js
 * or:
 * https://bitbucket.org/perfpie/crackle/src/cb095e1d4a2560fdaa47cfb17721b530c4cebc59/src/client/containers/company/wizards/CompanyCreationWizard/?at=CRAC-49
 *
 */
class WizardFormWrapped extends React.Component {
  render() {
    // Reason for capitalizing steps, is to enable react render
    // react component names are required to be Capitalized. read more: https://reactjs.org/warnings/unknown-prop.html
    const {
      steps: Steps,
      title,
      userInput,
      dispatch,
      form,
      submit,
      invalid,
      submitting,
      pristine,
      addBottomMargin, // sometimes we need to add bottom margin due to intercom button overlaying on top of buttons
      formValues,
      className,
      ...rest
    } = this.props;

    return (
      <div className={className} style={{ marginBottom: addBottomMargin ? 60 : 0 }}>
        {title !== '' ? <h1>{title}</h1> : null}
        <input style={{ display: 'none' }} />
        <input type="password" style={{ display: 'none' }} autoComplete="new-password" />
        <Wizard>
          <Steps
            userInput={userInput}
            dispatch={dispatch}
            form={form}
            submit={submit}
            invalid={invalid}
            pristine={pristine}
            submitting={submitting}
            formValues={formValues}
            {...rest}
          />
        </Wizard>
      </div>
    );
  }
}

WizardFormWrapped.propTypes = {
  form: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  // insideDialog:    PropTypes.bool.isRequired,
  addBottomMargin: PropTypes.bool.isRequired,
  steps: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.element]).isRequired,
  userInput: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  title: PropTypes.string,
  formValues: PropTypes.object,
  className: PropTypes.string,
};

WizardFormWrapped.defaultProps = {
  userInput: {},
  title: '',
  wizardFormRef: null,
  formValues: {},
  className: '',
};

// connecting to store for access to redux form
const enhance = connect((storeState, ownProps) => {
  // Create a form value selector for the current dialog via the form name
  const formStateSelector = formValueSelector(ownProps.form);

  // the formStateSelector receive the redux store and string valuesToWatch (coma separated)
  // of the form it wish to retrieve
  // ! don't send text fields name to formValueSelector, it will cause re-render on every key stroke!

  // if we want to watch all changes of form values, pass a watchValues prop to WizardForm
  // in this case, we also watch for specific values (not so wise...)
  if (ownProps.watchAllValues && ownProps.valuesToWatch && ownProps.valuesToWatch.length > 0) {
    const userInput = formStateSelector(storeState, ...ownProps.valuesToWatch);

    return {
      userInput,
      formValues: getFormValues(ownProps.form)(storeState),
      formSyncErrors: getFormSyncErrors(ownProps.form)(storeState),
    };
    // if we want to watch all the form valuesToWatch changes
  } else if (ownProps.watchAllValues) {
    return {
      formValues: getFormValues(ownProps.form)(storeState),
      formSyncErrors: getFormSyncErrors(ownProps.form)(storeState),
    };
  } else if (ownProps.valuesToWatch && ownProps.valuesToWatch.length > 0) {
    // return just the valuesToWatch to be watched by the user input - array of strings
    const userInput = formStateSelector(storeState, ...ownProps.valuesToWatch);

    return {
      userInput,
      formSyncErrors: getFormSyncErrors(ownProps.form)(storeState),
    };
  }
  // if we don't want to watch any inputs change
  return {
    formSyncErrors: getFormSyncErrors(ownProps.form)(storeState),
  };
});

export const WizardForm = enhance(
  reduxForm({
    // form name, received from props
    // destroyOnUnmount, received from props
    // validate, received from props
    forceUnregisterOnUnmount: true, // Unregister fields on unmount
  })(WizardFormWrapped),
);

WizardForm.propTypes = {
  watchAllValues: PropTypes.bool,
  destroyOnUnmount: PropTypes.bool,
  addBottomMargin: PropTypes.bool,
  initialValues: PropTypes.object,
  valuesToWatch: PropTypes.arrayOf(PropTypes.string),
  touchOnBlur: PropTypes.bool,
  touchOnChange: PropTypes.bool,
};

WizardForm.defaultProps = {
  watchAllValues: false,
  destroyOnUnmount: false,
  addBottomMargin: false,
  initialValues: {},
  valuesToWatch: [],
  touchOnBlur: true,
  touchOnChange: false,
};
