import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../app/theme';

export const useFeedPostStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: 24,
      marginBottom: 24,
      [theme.breakpoints.down('xs')]: {
        borderRadius: 0,
        padding: '16px 24px',
      },
      '& >div:last-child': {
        marginBottom: 0,
      },
    },
    contentsContainer: {
      marginBottom: 24,
    },
  }),
);

export const useFeedPostHeaderStyles = makeStyles(() => ({
  headerContainer: {
    marginBottom: 16,
  },
  author: {
    padding: 0,
  },
  avatar: {
    marginRight: 16,
  },
  primaryText: {
    color: colors.black,
    fontWeight: 'bold',
  },
  secondaryText: {
    color: colors.black_lighter,
  },
  topic: {},
  contextMenuButton: {
    marginLeft: 12,
  },
}));

export const useFeedPostActionItemsStyles = makeStyles(() => ({
  actionItemsContainer: {
    padding: '16px 24px',
    boxShadow: '0px 1px 4px rgba(51, 53, 82, 0.12), 0px 1px 6px rgba(51, 53, 82, 0.12);',
    marginBottom: 16,
  },
  actionItemsTitle: {
    fontWeight: 'bold',
    color: colors.black_lighter,
    textTransform: 'uppercase',
  },
  actionItemList: {
    margin: 0,
    listStyleType: 'circle',
    paddingInlineStart: '24px',
    '& li::marker': {
      color: colors.cherry,
      fontSize: '1.5em',
    },
  },
  actionItemListItem: {
    margin: '8px 0',
  },
}));

export const useFeedPostCommentStyles = makeStyles((theme) =>
  createStyles({
    commentContextMenuButton: {
      marginLeft: 8,
      [theme.breakpoints.up('md')]: {
        opacity: 0,
        transition: 'opacity ease-in 150ms',
      },
    },
    commentContainer: {
      margin: '8px 0',
      [theme.breakpoints.up('md')]: {
        '&:hover': {
          '& $commentContextMenuButton': {
            opacity: 1,
          },
        },
      },
    },
    commentAuthor: {
      marginRight: 8,
    },
    commentAvatarContainer: {
      marginRight: 8,
      [theme.breakpoints.down('sm')]: {
        marginRight: 2,
      },
    },
    commentContentsContainer: {
      flexGrow: 1,
      padding: '12px 16px',
      backgroundColor: colors.blackOpacity(0.05),
      borderRadius: 16,
    },
  }),
);

export const useFeedPostFilesStyles = makeStyles((theme) =>
  createStyles({
    filesContainer: {
      margin: 0,
      minWidth: 450,
      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto',
      },
    },
    postFilesImage: {
      display: 'block',
      backgroundColor: colors.white,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundOrigin: 'content-box',
      position: 'relative',
      height: 180,
      width: '100%',
      borderRadius: 4,
      filter: 'drop-shadow(0px 1px 4px rgba(51, 53, 82, 0.12))',
    },
    fileImageAvatar: {
      position: 'absolute',
      color: 'black',
      bottom: 8,
      right: 0,
      opacity: 0.6,
    },
    fileItem: {
      backgroundColor: colors.blackOpacity(0.05),
      borderRadius: 32,
      marginBottom: 8,
      paddingLeft: 24,
      paddingRight: 24,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
  }),
);

export const useFeedPostReactionsStyles = makeStyles(() =>
  createStyles({
    reactionsRoot: {
      paddingTop: 16,
      paddingBottom: 8,
    },
    likeButton: {
      border: '1px solid #EAE8E6',
      width: 111,
    },
  }),
);
