import { IMarketingPlanContext } from './types';

export enum MarketingPlanActionType {
  SET_VALUES = 'SET_VALUES',
  OPEN_UPLOAD_DIALOG = 'OPEN_DELETE_DIALOG',
  CLOSE_UPLOAD_DIALOG = 'CLOSE_DELETE_DIALOG',
}

interface ReducerAction {
  type: string;
  payload?: any;
}

export type MarketingPlanAction =
  | { type: MarketingPlanActionType.SET_VALUES; payload: Partial<IMarketingPlanContext> }
  | { type: MarketingPlanActionType.OPEN_UPLOAD_DIALOG }
  | { type: MarketingPlanActionType.CLOSE_UPLOAD_DIALOG };

export type MarketingPlanDispatch = (action: MarketingPlanAction) => void;

export const marketingPlanReducer = (
  marketingPlanContextState: IMarketingPlanContext,
  action: ReducerAction,
): IMarketingPlanContext => {
  const { type, payload } = action;

  switch (type) {
    case MarketingPlanActionType.SET_VALUES: {
      return {
        ...marketingPlanContextState,
        ...payload,
      };
    }
    case MarketingPlanActionType.OPEN_UPLOAD_DIALOG:
      return {
        ...marketingPlanContextState,
        uploadMarketingPlanDialogIsOpen: true,
      };
    case MarketingPlanActionType.CLOSE_UPLOAD_DIALOG:
      return {
        ...marketingPlanContextState,
        uploadMarketingPlanDialogIsOpen: false,
      };

    default:
      return marketingPlanContextState;
  }
};
